@import '../../../style/variables';

.main-content {
  padding: 80px 40px 24px 24px;
  flex-grow: 1;
  // Main title
  h3 {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
    font-weight: 600;
  }
  .form{
    width: 75%;
  }
}