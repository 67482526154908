@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  // Main title
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  :global(.custom-checkbox) {
    padding-bottom: 25px;
    label {
      font-weight: 600;
    }
  }
}

// Block style
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  padding-top: 24px;
}

.form {
  padding: 0 24px 0 24px;
  fieldset {
    border-width: 0;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  .row {
    display: flex;
    margin: 0 -12px;
    padding-bottom: 4px;
  }
  .column {
    padding: 0 12px;
    padding-bottom: 20px;
    flex-shrink: 1;
    width: 33.333%;
  }
}

.asset-section {
  padding-bottom: 24px;
  &.last {
    padding-bottom: 0;
  }
}

// Main footer inlcude add link and buttons group
.footer {
  .buttons {
    display: flex;
    button,
    :global(.button) {
      margin-right: 16px;
    }
  }
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: right;
  .required {
    color: $error-border;
    font-size: 12px;
  }
}