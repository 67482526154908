.d-grid {
    display: grid;
}

.grid-4-columns {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-column-25 {
    column-gap: 25px;
}

.gap-row-20 {
    row-gap: 20px;
}