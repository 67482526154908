@import "../../style/variables";

.admin-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.admin-content {
  padding: 30px 20px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.selection-container {
  width: 220px;
  flex-grow: 1;
  flex-shrink: 0;
  .selection-wrapper {
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 5px;
    padding: 20px;
    width: 220px;
    min-height: 190px;
    flex-grow: 1;
    flex-shrink: 0;
  }
  .search {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
  }
}

.main-content {
  display: flex;
  flex-grow: 1;
  .content-wrapper {
    padding: 85px 44px 24px 24px;
    flex-grow: 1;
    .topic-wrapper {
      margin-bottom: 30px;
      .topic {
        font-size: 18px;
        line-height: 26px;
        color: $default;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid $border;
      }
      .content {
        margin-top: 15px;
      }
    }
  }
  li {
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid $border;
    .topic {
      font-size: 14px;
      line-height: 20px;
      color: $default;
      font-weight: normal;
      &.selected {
        font-weight: bold;
        color: $hover-color;
        .icon-arrow {
          transform: rotate(90deg);
        }
      }
    }

    .content > * {
      display: none;
      font-size: 12px;
      line-height: 18px;
      color: $default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:empty) {
        &:first-child {
          display: block;
          padding-top: 2px;
        }
      }
    }
  }
}

.icon {
  background: url("../../images/icon-help.svg") no-repeat center center;
  background-color: $logo;
  border-radius: 50%;
  content: "";
  display: block;
  height: 24px;
  margin-right: 12px;
  width: 24px;
}
