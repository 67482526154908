@import '../../style/variables';

$icons: home, properties, assets, forms, returns, reports;

.navigation {
  border-right: $border solid 1px;
  margin-right: 15px;
  position: relative;
  nav {
    padding: 12px 13px;
    // Navigation list
    li {
      font-size: 14px;
      font-weight: 600;
      padding: 3px 0;
      a {
        align-items: center;
        color: $default;
        display: flex;
        padding: 10px;
        &::before {
          content: '';
          display: block;
          flex-shrink: 0;
          height: 16px;
          margin: 1px 0;
          margin-right: 12px;
          width: 16px;
        }
        //Different icons of navigation item
        @each $icon in $icons {
          &.#{$icon} {
            &::before {
              background: url('../../images/icon-#{$icon}.svg');
            }
          }
        }
        // Hover status
        &:hover {
          background-color: $hover-background;
          border-radius: $radius;
          color: $hover-color;
          text-decoration: none;
          @each $icon in $icons {
            &.#{$icon} {
              &::before {
                background: url('../../images/icon-#{$icon}-active.svg');
              }
            }
          }
        }
      }
      /* Active status */
      &.active {
        a {
          background-color: $hover-background;
          border-radius: $radius;
          color: $hover-color;
          @each $icon in $icons {
            &.#{$icon} {
              &::before {
                background: url('../../images/icon-#{$icon}-active.svg');
              }
            }
          }
        }
      }
      &.disabled {
        a {
          pointer-events: none;
          cursor: default;
          opacity: .4;
          &:hover {
            background-color: transparent;
            color: $default;
          }
          @each $icon in $icons {
            &.#{$icon} {
              &::before {
                background: url('../../images/icon-#{$icon}.svg');
              }
            }
          }
        }
      }
    }
  }
  //Accoridion icon to expand/collapse the navigation
  i {
    align-items: center;
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    bottom: 20px;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    position: absolute;
    right: -15px;
    width: 30px;
    &::after {
      background: url('../../images/chevron.svg') no-repeat;
      content: '';
      display: block;
      height: 12px;
      width: 8px;
      transition: 0.5s;
    }
    &:hover {
      border-color: $hover-color;
      background-color: $hover-background;
    }
  }
  //Bottom link group
  .bottom-links {
    border-top: $border solid 1px;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 10px 0;
    position: absolute;
    right: 0;
    a {
      color: $primay;
      font-size: 12px;
      line-height: 16px;
      margin-left: 12px;
    }
  }
}

//Collapsed status defined on parent element,
:global(.collapsed) {
  .navigation {
    nav {
      li {
        a,
        button {
          &::before {
            margin-right: 0;
          }
          //Hide the text of navigation
          span {
            display: none;
          }
        }
      }
    }
    //Rotate accordion icon
    i {
      &::after {
        transform: rotate(180deg);
      }
    }
    //hide the bottom link group
    .bottom-links {
      display: none;
    }
  }
}
