.blockColumn3 {
    width: 40%;
}

.FieldInput {
    margin-top: 33px;

    &.isInReadOnlyMode {
        margin-top: 0;
    }
}