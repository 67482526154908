@import '../../style/variables';

.blocks {
  align-items: flex-start;
  display: flex;
}

.add-property-block {
  background-color: $active;
  border: $primay solid 1px;
  border-radius: $radius-large;
  box-sizing: border-box;
  color: $primay;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  width: 240px;
  height: 200px;
  margin-right: 24px;
  padding: 47px 40px 0 40px;
  text-align: center;
  text-decoration: none;
  &::before {
    background: url('../../images/icon-add-property.svg') no-repeat center
      center;
    content: '';
    display: block;
    height: 32px;
    margin: 0 auto;
    margin-bottom: 27px;
    width: 32px;
  }
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: $primay;
  }
}

.column {
  flex-grow: 1; 
}
