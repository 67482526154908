@import '../../style/variables';

.validate-properties {
  :global(.table-layout) {
    width: 100%;
    th {
      white-space: nowrap;
      &:first-child {
        width: 20px;
      }
      &:last-child {
        width: 40px;
      }
    }
    td {
      .noWrap {
        white-space: nowrap;
      }
    }
  }
}
