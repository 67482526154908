@import '../../style/variables';

.tabs {
  border-bottom: $block solid 1px;
  ul {
    display: flex;
    position: relative;
    top: 1px;
    li {
      color: $header;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      margin-right: 32px;
      &:hover {
        opacity: 0.7;
      }
      &::after {
        content: '';
        display: block;
        height: 4px;
        margin-top: 12px;
        width: 100%;
      }
      &.active {
        cursor: default;
        color: $primay;
        &::after {
          background-color: $primay;
        }
        &:hover {
          opacity: 1;
        }
      }
      &.disabled {
        cursor: default;
        opacity: .3;
      }
    }
  }
}

.tab-content {
  display: none;
  padding: 20px 0 0 0;
  &.active {
    display: block;
  }
}