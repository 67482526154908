@import "../../../style/variables";

.main-content {
  padding: 80px 40px 24px 24px;
  flex-grow: 1;
  h3 {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
    font-weight: 600;
  }

  [data-col]:first-child {
    padding-left: 20px;
  }

  [data-col^="year"] {
    width: 50px;
    text-align: center;
  }

  [data-col="actions"] {
    width: 100px;

    &:last-of-type {
      padding: 0 40px 0 90px;
    }
  }
}

.select {
  display: flex;
  gap: 15px;
  div {
    &:first-child {
      padding: 0;
      font-size: 14px;
      line-height: 20px;
    }

    &:last-child {
      width: 200px;
    }
  }
}

.modal {
  height: 85%;
  width: 50%;
  border: $block solid 1px;
  border-radius: $radius-large;
  background-color: $blank;
}

.modal-wrapper {
  padding: 40px;
  h3 {
    margin-bottom: 25px;
  }
  .form {
    padding: 0;
  }
  .section {
    border: none;
  }
}

.buttons {
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.add-button-layout {
  position: relative;
}

.options {
  background-color: $blank;
  border: $border solid 1px;
  border-radius: $radius;
  position: absolute;
  right: 0;
  overflow-y: auto;
  z-index: 10;
  min-width: 100%;
  ul {
    padding: 4px 0;
  }
  li {
    cursor: pointer;
    padding: 4px 8px;
    white-space: nowrap;
    a {
      background-color: transparent;
      border-width: 0;
      color: $default;
      font-size: 14px;
      text-decoration: none;
    }
    &.active,
    &.active:hover {
      color: $blank;
      background-color: $primay;
    }
    &:hover {
      background-color: $hover-background;
    }
  }
}