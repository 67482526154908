.toggleCheckbox {
  label {
    margin-bottom: 0;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }

  :global(s-toggle) {
    display: flex;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
