.main-content {
    flex: 1 1 0%;
    width: 100px;
    padding: 80px 40px 66px 23px;

    :global(.FieldInput) {
        margin-bottom: 0;
    }
}

.textTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
}

.divider1 {
    margin-top: 16px;
    margin-bottom: 23px;
}

.sync-button{
    margin-top:10px;
    float: right;
}