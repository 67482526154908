@import '../../style/variables';

.title {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.plan {
  display: flex;
  justify-content: space-between;
  background-color: $geryer-background;
  border-radius: 8px;
  padding: 20px 24px;
  font-size: 12px;
  line-height: 16px;
  color: $default;
  margin-bottom: 32px;

  .taxavaHeader {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: $primary-logo;
    span {
      color: $logo;
    }
  }

  .perYear {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.totalRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 16px 0;
  border-top: 1px solid $block;
}

.availableReturns {
  padding: 0 20px;
  .label {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: $header;
  }
  .value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}


.table-layout {
  overflow: auto;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  a {
    margin: 0 0 0 6px;
  }
}

.app-logo {
  height: 30px;
}