@import '../../style/variables';

.s-checkbox {
  input[type='checkbox'] + label {
    min-height: 24px;
    align-items: center;
    display: flex;
  }
  label.check-label {
    min-height: 16px;
    &::before,
    &::after {
      box-shadow: none !important;
    }
    &::after {
      top: auto;
    }
    span[data-tip='React-tooltip'] {
      display: block;
      line-height: 16px;
    }
  }
}
