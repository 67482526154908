@mixin formControl() {

  .form-control {
    display: block;

    //Label for input field
    .label {
      align-items: center;
      display: flex;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 9px;
      & > span,
      span[data-tip='React-tooltip'] {
        display: block;
        line-height: 16px;
      }
      &.label-recommended {
        justify-content: space-between;
      }
      small {
        color: $header;
        font-size: 12px;
        line-height: 1;
      }
    }
    //required marker '*'
    .required {
      color: $error-border;
      margin-left: 2px;
      line-height: 1;
    }
    //tooltip for the input field
    .tooltip {
      position: relative;
      margin-left: 8px;
      &::before {
        background: url('../images/icon-info.svg') no-repeat center center;
        content: '';
        display: block;
        height: 12px;
        width: 12px;
      }
      .tip {
        background-color: $blank;
        border: $border solid 1px;
        border-radius: $radius;
        display: none;
        padding: 8px 12px;
        position: absolute;
        top: -12px;
        left: 14px;
        white-space: nowrap;
      }
      /* Hover on the tooltip,tip appear */
      &:hover {
        .tip {
          display: block;
        }
      }
    }
  }
}

@mixin formControlErrorMessage {

  .error-message {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $error-border;
    margin: 8px 0;
    white-space: nowrap;
    &.long-message {
      white-space: normal;
    }
    &::before {
      background: url('../images/icon-error.svg') no-repeat center center/16px 16px;
      content: '';
      display: block;
      height: 16px;
      flex-shrink: 0;
      margin-top: 1px;
      margin-right: 8px;
      width: 16px;
    }
  }
}