@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  // Main title
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

// Block header
.header {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
  }
  // Remove and edit button
  .operate {
    align-items: center;
    display: flex;
  }
  .remove {
    background: url('../../../images/icon-delete.svg') no-repeat center center;
    display: block;
    margin-left: 20px;
    height: 16px;
    width: 16px;
  }
  .edit {
    background: url('../../../images/icon-edit.svg') no-repeat center center;
    display: none;
    height: 16px;
    margin-left: 20px;
    width: 16px;
  }
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  .required {
    color: $error-border;
    font-size: 12px;
  }
}

.toggle-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}

.form {
  padding: 0 24px;
  fieldset {
    border-width: 0;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  .checkbox-fieldset {
    .custom-checkbox {
      padding-bottom: 24px;
      legend {
        display: none;
      }
      &:last-child {
        padding-bottom: 12px;
      }
    }
  }
  .row {
    display: flex;
    margin: 0 -12px;
    padding-bottom: 4px;
  }
  .column {
    padding: 0 12px;
    padding-bottom: 20px;
    flex-shrink: 1;
    width: 33.333%;
    &.column-up-checkbox {
      padding-bottom: 6px;
    }
  }
  // Address or data row column style
  .address-row,
  .row-date {
    display: flex;
    margin: 0 -12px;
  }
  .address-row {
    margin: 0 -8px;
  }
  .address-column {
    margin: 0 8px;
    width: 30%;
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: 25%;
    }
  }
  .column-date {
    margin: 0 12px;
    width: 50%;
  }
  // Reposition checkbox
  :global(.custom-checkbox) {
    padding: 0 0 24px 0;
    label {
      font-size: 15px;
    }
  }
}

.owner-infor {
  :global(.custom-checkbox) {
    label {
      font-size: 12px;
    }
  }
  .custom-checkbox {
    padding-bottom: 12px;
    legend {
      display: none;
    }
  }
}

// Main footer inlcude add link and buttons group
.footer {
  .add-link {
    background-color: transparent;
    border-width: 0;
    align-items: center;
    color: $primay;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    margin-left: 24px;
    &::before {
      background: url('../../../images/icon-add.svg') no-repeat center center;
      content: '';
      display: block;
      height: 16px;
      margin-right: 12px;
      width: 16px;
    }
  }
  .buttons {
    align-items: center;
    display: flex;
    button,
    :global(.button) {
      margin-right: 16px;
    }
  }
}

/* Finish status */
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  
  &.collapsed {
    .header {
      padding: 16px 24px;
      h3 {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        &::before {
          background: url('../../../images/icon-arrow-right.svg') no-repeat
            center center;
          content: '';
          display: block;
          height: 16px;
          margin-right: 12px;
          width: 16px;
        }
      }
      .edit {
        display: block;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .form {
      display: none;
    }
  }
}

.divider1 {
  margin-top: 12px;
  margin-bottom: 16px;
}