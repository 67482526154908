.main-content {
    :global(.FieldInput) {
        margin-bottom: 0;
    }
}

.blockColumn3 {
    width: 40%;
}

.divider1 {
    margin-top: 29px;
    margin-bottom: 15px;
}

.CustomCheckbox {
    margin-bottom: 6px;
}