@import "../../style/variables";

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.chip {
  display: inline-flex;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  background-color: #f4f5f6;
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
}

.btnRemove {
  display: inline-flex;
  border-radius: 50%;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj48Zz48Zz48cGF0aCBmaWxsPSIjNmY2ZjZmIiBkPSJNMy4yODggOC4wMjhMNS4zMSA2LjAwNyAzLjI4OCAzLjk4NWwuNjg0LS42ODMgMi4wMiAyLjAyMSAyLjAyMi0yLjAyLjY4NC42ODItMi4wMjIgMi4wMjIgMi4wMjIgMi4wMjEtLjY4NC42ODNMNS45OTMgNi42OSAzLjk3MiA4LjcxMXptNS4wNjggMy41MTZhNS44ODkgNS44ODkgMCAwIDAgMS45MDctMS4yNiA1LjgwNyA1LjgwNyAwIDAgMCAxLjI3NC0xLjkxNGMuMzA5LS43NC40NjMtMS41MjguNDYzLTIuMzYzIDAtLjg0NS0uMTU0LTEuNjMzLS40NjMtMi4zNjNhNS45MjYgNS45MjYgMCAwIDAtMS4yNzQtMS45MDhBNS45MjYgNS45MjYgMCAwIDAgOC4zNTYuNDYyIDYuMDE0IDYuMDE0IDAgMCAwIDUuOTkzIDBDNS4xNTggMCA0LjM3LjE1NCAzLjYzLjQ2MmE1LjgwNiA1LjgwNiAwIDAgMC0xLjkxNSAxLjI3NCA1Ljg4OSA1Ljg4OSAwIDAgMC0xLjI2IDEuOTA4QTYuMDk2IDYuMDk2IDAgMCAwIDAgNi4wMDdjMCAuODM1LjE1MiAxLjYyMi40NTYgMi4zNjMuMzAzLjc0LjcyMyAxLjM3OCAxLjI2IDEuOTE0YTUuNzcxIDUuNzcxIDAgMCAwIDEuOTE0IDEuMjZjLjc0LjMwNCAxLjUyOC40NTYgMi4zNjMuNDU2Ljg0NCAwIDEuNjMyLS4xNTIgMi4zNjMtLjQ1NnoiLz48L2c+PC9nPjwvc3ZnPg==);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding: 0;
}

.btnRemoveAll {
  margin-left: 6px;
  font-size: 12px;
  color: #117bab;
}
