.divider1 {
    margin-top: 12px;
    margin-bottom: 16px;
}

.textSelectedContainer {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    height: 21px;
    padding: 0;

    .textUnselected {
        display: none;
    }

    &:hover {
        .textSelected {
            display: none;
        }

        .textUnselected {
            display: flex;
            text-decoration: underline;
            color: var(--red-dark);
        }
    }
}

.FieldToggleCheckbox {
    margin-bottom: 7px;
}

.width-130 {
    width: 130px;
}

.blockSelected {
    margin-bottom: 18px;
}