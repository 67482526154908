@import "../../style/variables";

s-dialog.container {
  button[data-ref="dismiss-button"]{
    &[aria-label="Close"]  {
      display: none;
    }
  }

  div[data-ref="content-container"] {
    padding: 0;
    background-color: transparent;
  }

  &[open] {
    z-index: 1001;

    &::before {
      display: none;
    }

    + .backdrop {
      background: rgba(26, 26, 26, 0.5);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }
  }
}
