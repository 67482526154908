@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.buttons {
  border-top: $border solid 1px;
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.add-button-layout {
  position: relative;
}

