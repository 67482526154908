@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.tableContainer {
  margin-top: 23px;
}

.buttons {
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.float-right {
  float: right;
  z-index: 1;
}