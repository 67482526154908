@import "../../style/variables";
@import '../../style/mixins';

@include formControl();

.form-control {
  width: 100%;
  //The input field
  .input-control {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    // Disabled status
    &:global(.disabled) {
      color: $default;
      background-color: $disabled;
      opacity: 0.6;
    }
    &.with-icon {
      align-items: center;
      display: flex;
      &::before {
        background: url("../../images/icon-search.svg") no-repeat center center;
        content: "";
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 10px;
        width: 16px;
      }
    }
    &.resizable {
      textarea {
        resize: both;
      }
    }
  }
  /* Error status */
  &:global(.error) {
    .input-control {
      background-color: $error-background;
      border-color: $error-border;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();

