@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.table-layout {
  th {
    &:nth-child(2) {
      width: 140px;
    }
    &:nth-child(1) {
      padding-left: 18px;
      width: 40px;
    }
  }
  tr {
    td {
      &:nth-child(1) {
        padding-left: 18px;
      }
    }
  }
  :global(.form-control) {
    padding: 0;
    width: 300px;
  }
}

.blocks {
  display: flex;
  margin: 0 -5px;
  flex-wrap: wrap;
  span {
    border: $border solid 1px;
    border-radius: $radius;
    display: block;
    font-size: 12px;
    line-height: 21px;
    margin: 2px 5px;
    padding: 0 10px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.buttons {
  align-items: center;
  display: flex;
  padding: 24px 0;
  button,
  :global(.button) {
    margin-right: 20px;
  }
}

.results {
  padding-bottom: 20px;
  width: 902px;
  .buttons {
    padding: 0 0 24px 0;
    :global(.default-button) {
      margin-left: 0;
      margin-right: 20px;
    }
  }
  :global(.mappedfile-result) {
    button {
      height: auto;
      padding: 0;
    }
  }

  .mappedFileName {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: unset;
    color: $black;
    text-transform: unset;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    padding-right: 8px;
    white-space: nowrap;
  }

  .mappedFileColumn1 {
    flex: 3 0;
  }

  .mappedFileColumn2 {
    flex: 1 0 104px;
  }

  .mappedFileColumn3 {
    flex: 1 0 117px;
  }

  .mappedFileColumn4 {
    flex: 1 0 93px;
  }
}

//tooltip for the input field
.tip-column {
  align-items: center;
  display: flex;
}


.tooltip {
  position: relative;
  margin-left: 8px;
  &::before {
    background: url('../../../images/icon-info.svg') no-repeat center center;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
  }
  .tip {
    border-radius: $radius;
    display: none;
    position: absolute;
    padding: 12px;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    box-sizing: border-box;
    background-color: $blank;
    box-shadow: $shadow -2px 0 10px;
    margin-left: -59px;
    white-space: normal;
    top: 24px;
    width: 130px;
    z-index: 100;
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin-left: 8px;
      top: 0;
      left: 50%;
      box-sizing: border-box;
      border: 6px solid $blank;
      border-color: transparent transparent $blank $blank;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, .06);
    }
  }
  /* Hover on the tooltip,tip appear */
  &:hover {
    .tip {
      display: block;
    }
  }
}