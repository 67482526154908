.btnEdit {
    height: 21px;
}

.textTitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.06px;
}

.divider1 {
    margin-top: 8px;
    margin-bottom: 14px;
}