@import '../../../style/variables';

.content {
  display: flex;
}

.main-content {
  padding: 30px 40px 24px 24px;
  flex: 1;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 34px;
  }
}

.items-container {
  margin-top: 24px;
}

.items-container-wrapper {
  margin: -5.5px -8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.form-item {
  width: 228px;
  margin: 5.5px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-item-locked {
  .form-item-preview {
    border: 2px solid $primay;
    &:hover {
      .preview-mask {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}

.form-item-preview {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid $block;
  overflow: hidden;
}

.preview-mask {
  display: none;
  position: absolute;
  inset: 0;
  background-color: rgba($color: $black, $alpha: 0.5);
  padding: 102px 34px 0 26px;
  &>:not(:first-child) {
    margin-left: 20px;
  }
}

.checkbox {
  position: absolute;
  inset: 15px auto auto 15px;
}

.form-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-item-name {
  white-space: normal;
  margin: 16px 16px 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.buttons {
  display: flex;
  padding: 47px 0 24px;
  :global(.button),
  button {
    margin-right: 20px;
  }
}

.status-panel {
  flex: none;
  width: 270px;
  padding: 0 20px;
  background-color: $geryer-background;
  display: flex;
  flex-direction: column;
}

.status-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  padding: 17px 0;
  border-bottom: 1px solid $block;
}

.status-content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.status-item {
  display: flex;
  &:not(:first-child) {
    margin-top: 18px;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('../../../images/item-status-unchecked.svg');
  }
  &.status-item-checked {
    &::before {
      background: url('../../../images/item-status-checked.svg');
    }
  }
}

.status-item-name {
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;

  &.form-item-locked {
    &::before {
      display: inline-block;
      content: '';
      width: 12.3px;
      height: 13.5px;
      background: url('../../../images/lock.svg');
      margin-right: 8px;
    }
  }
}