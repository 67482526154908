@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  .title {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
  .main {
    padding-top: 8px;
  }
}

.list {
  padding-top: 14px;
  padding-bottom: 18px;
  width: 100%;
  li {
    padding: 6px 0;
  }
}
.description {
  background-color: $geryer-background;
  border-radius: $radius-large;
  padding: 24px;
  h5 {
    color: $primay;
    font-weight: 600;
    line-height: 28px;
  }
  .info {
    color: $header;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
}

.buttons {
  border-top: $block solid 1px;
  padding: 24px 0;
  :global(.button) {
    margin-right: 20px;
  }
}

.refresh {
  padding-bottom: 12px;
}