@import '../../style/variables';

.s-radio {
  label:not(:first-child) {
    margin-top: 0;
  }
  fieldset {
    .label {
      &::before,
      &::after {
        box-shadow: none !important;
      }
      span {
        align-self: center;
        display: block;
        line-height: 16px;
      }
    }
  }
}
