.textTitle {
    color: var(--grayscale-dark);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
    display: flex;
}

.textLink,
.textValue {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.056px;
}

.textLink {
    text-decoration-line: underline;
    color: var(--blue-dark) !important;
}