@import '../../style/variables';

.content {
  border: $block solid 1px;
  border-radius: $radius-large;
  flex-grow: 1;
  //Init counter of content attribute
  counter-reset: number;
  // Step infor block,it is above the step block
  .infor {
    padding: 0 24px 20px 24px;
    // Step block title
    h3 {
      display: inline-flex;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      padding: 19px 0 6px 0;
    }
    .details {
      align-items: flex-end;
      justify-content: space-between;
      display: flex;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 24px;
      width: calc(100% - 400px);
    }
    .links {
      text-align: right;
    }
    :global(.link) {
      color: $primay;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  //Step block
  .block {
    border-top: $block solid 1px;
    padding: 19px 24px 22px 24px;
    position: relative;
    counter-increment: number;
    // Step icon,it place on the right side of block
    &::after {
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      display: block;
      height: 113px;
      position: absolute;
      top: 28px;
      right: 26px;
      width: 113px;
    }
    strong {
      align-items: center;
      color: $primay;
      display: inline-flex;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      padding-bottom: 5px;
      // The index badge of block
      &::before {
        align-items: center;
        border: $primay solid 1px;
        border-radius: 50%;
        content: counter(number);
        display: flex;
        font-size: 14px;
        height: 18px;
        justify-content: center;
        margin-right: 12px;
        width: 18px;
      }
      // Special style for prepare returns
      &.last {
        &::before {
          background-color: $primay;
          color: $blank;
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 14px;
      width: calc(100% - 400px);
    }
    // Define buttons position
    .buttons {
      align-items: center;
      display: flex;
    }
    .success-button {
      display: none;
    }
    :global(.link) {
      color: $primay;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin-left: 20px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    :global(.disabled-button) {
      display: none;
    }
    /* Disabled status,hide the primary buttons and show the disabled buttons */
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      strong {
        color: $default;
        &::before {
          border-color: $default;
        }
        &.last {
          &::before {
            background-color: transparent;
            color: $default;
          }
        }
      }
      :global(.primary) {
        display: none;
      }
      :global(.disabled-button) {
        display: inline-flex;
      }
      :global(.link) {
        cursor: default;
        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
      :global(.button) {
        background-color: $geryer-background;
        cursor: default;
      }
    }
    /* Success status,hide the primary buttons and show the success buttons */
    &.success {
      strong {
        color: $successful;
        &::before {
          background-image: url('../../images/icon-success.svg');
          content: '';
          border-width: 0;
          height: 20px;
          width: 20px;
        }
      }
      :global(.primary) {
        display: none;
      }
      .success-button {
        display: inline-flex;
      }
    }
    /* Success status,change the icons style */
    &.active {
      background-color: $active;
      border: $primay solid 1px;
      margin: 0 -1px;
    }
    &:last-child {
      &.active {
        border-radius: 0 0 $radius-large $radius-large;
      }
    }
    &.company {
      &::after {
        background-image: url('../../images/icon-company-large.png');
        background-size: 100px 100px;
      }
    }
    &.legal-entity {
      &::after {
        background-image: url('../../images/icon-legal-entity-large.svg');
        background-size: 96px 96px;
      }
    }
    &.properties {
      &::after {
        background-image: url('../../images/icon-properties-large.png');
        background-size: 109px 109px;
      }
    }
    &.setup {
      &::after {
        background-image: url('../../images/icon-setup-large.png');
        background-size: 90px 90px;
      }
    }
  }
}
