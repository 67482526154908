@import "../../style/variables";

.bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  min-height: 53px;
  gap: 20px;

  &:empty {
    display: none;
  }

  .searchInput {
    margin-right: auto;
    min-width: 200px;
  }

  s-dropdown[open] > [slot='dropdown-content'] {
    z-index: 10000;
  }

}

.tableWrapper {
  width: 100%;
  overflow: auto;
  position: relative;

  tr:global(.selected) td {
    background-color: #f7fdff;
  }
}

.select-col {
  position: sticky;
  left: -1px;
  z-index: 100;
  background: #fff;

  > :first-child {
    display: flex;
    width: 28px;
    justify-content: center;
    align-items: center;
    color: var(--color-blue-dark);

    div {
      display: flex !important;
    }
  }
}

.pagination {
  justify-content: flex-end;
  display: flex;
  padding: 10px 0px;
}

.chips {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4c4c4;
}

.col-manage {
  white-space: nowrap;
}

table td.loadingRow {
  text-align: center;
  padding-top: 9px !important;
  padding-bottom: 2px !important;
}

.icon-info {
  &::before {
    background: url('../../images/icon-info.svg') no-repeat center center;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    color: var(--color-blue-dark);
  }
}