@import '../../../style/variables';

.page {
  display: flex;
  height: calc(100vh - 65px);
  overflow: hidden;
  margin-left: -15px;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.main-content-top {
  padding: 30px 40px 24px 24px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.page-header-actions {
  display: flex;
  align-items: center;
}

.mode-select {
  min-width: 160px;
  margin-left: 16px;
  margin-right: 16px;
}

.page-content {
  flex: 1;
  overflow: hidden;
}

.pdf-viewer {
  height: 100%;
  border-top: 1px solid $border;
}