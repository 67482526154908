@import "../../../style/variables";

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.top-section {
  border-bottom: $block solid 1px;
  margin-bottom: 20px;
}

.tableContainer {
  margin-top: 23px;
}

.results {
  padding-bottom: 20px;
  max-width: 92%;
}

.buttons {
  border-top: $border solid 1px;
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.list {
  padding-top: 4px;
  padding-bottom: 8px;
  width: 100%;
  li {
    padding: 4px 0;
  }
}
.description {
  background-color: $geryer-background;
  border-radius: $radius-large;
  padding: 6px 24px 12px 24px;
  h5 {
    color: $primay;
    font-weight: 600;
    line-height: 28px;
  }
  .info {
    color: $header;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }
}
