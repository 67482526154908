@import '../../style/variables';
@import '../../style/mixins';

.container {
  &.hideNullOption {
    :global(s-select) {
      div[data-ref='select-ul-container'] {
        ul[data-ref='results'] {
          li:not([data-value]) {
            display: none;
          }
        }
      }
    }
  }

  :global(s-select) {

    div[data-ref='select-input-container'] {
      background: url('../../images/arrow-down.svg') no-repeat calc(100% - 18px) center;
      min-height: 36px;
      position: relative;
    }

    span[data-ref='select-value'] {
      margin-left: 12px;
    }

    div[data-ref='disclosure-icon'] {
      display: none;
      top: auto;
      height: 32px;
      width: 32px;

      s-icon {
        color: var(--color-gray-dark);
        font-size: 14px;
      }
    }

    :global(.contains-clear-button)[data-ref='select-input-container'] {
      padding-right: 36px;
      max-height: 70px;
      overflow-y: auto;
    }

    [data-ref='deselect-all-button'] {
      display: none;
    }

    &:global(.error) div[data-ref="select-input-container"], 
    &:global(.error):hover div[data-ref="select-input-container"] {
      background-color: $error-background;
    }
  }

  :global(s-select[aria-expanded='true']) {
    div[data-ref='select-input-container'] {
      background-image: url('../../images/arrow-up.svg');
    }
  }
}

//Label for input field
.label {
  align-items: center;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 9px;
  & > span,
  span[data-tip='React-tooltip'] {
    display: block;
    line-height: 16px;
  }
}

//required marker '*'
.required {
  color: $error-border;
  margin-left: 2px;
  line-height: 1;
}

//tooltip for the input field
.tooltip {
  position: relative;
  margin-left: 8px;
  &::before {
    background: url('../../images/icon-info.svg') no-repeat center center;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
  }
  .tip {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    display: none;
    padding: 8px 12px;
    position: absolute;
    top: -12px;
    left: 14px;
    white-space: nowrap;
  }
  /* Hover on the tooltip,tip appear */
  &:hover {
    .tip {
      display: block;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();

.floating-overlay {
  z-index: 1000;
}
