@import "../../style/variables";

.fieldset {
  display: flex;
  gap: 65px;
  padding: 24px;
  border-radius: 8px;
  background-color: $geryer-background;
  
  & + & {
    margin-top: 16px;
  }

  + form {
    margin-top: 25px;
  }
}

.field-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 2px;
  color: #6f6f6f;
  text-transform: uppercase;
}

.field-value {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
}

.info-row {
  background-color: #FFD1B2;
  border: #FF6600 solid 1px;
}