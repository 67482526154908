@import '../../style/variables';
@import '../../style/mixins';

@include formControl();

.form-control {
  width: 100%;

  //The input field
  .input-control {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    padding: 6px 12px;
    min-height: 36px;
    textarea {
      background-color: transparent;
      border-width: 0;
      color: $default;
      font-size: 14px;
      padding: 0;
      width: 100%;
      resize: none;
      &:hover,
      &:active,
      &:visited{
        border-width: 0;
      }
      &:focus-visible{
        outline: none;
      }
      &:focus{
        border: none;
        box-shadow: none;
      }
    }
    // Disabled status
    &:global(.disabled) {
      color: $default;
      background-color: $disabled;
      opacity: .6;
    }
    &.with-icon {
      align-items: center;
      display: flex;
      &::before {
        background: url('../../images/icon-search.svg') no-repeat center center;
        content: '';
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 10px;
        width: 16px;
      }
    }
    &.resizable{
      textarea{
        resize: both;
      }
    }
    &:focus-within {
      border: 1px solid $focus-color;
      box-shadow: 0 0 0 1px $focus-color;
    }
  }
  /* Error status */
  &:global(.error) {
    .input-control {
      background-color: $error-background;
      border-color: $error-border;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();
