@import '../../style/variables';

.header-bar {
  background-color: $hover-color;
  align-items: center;
  justify-content: space-between;
  display: none;
  height: 60px;
  padding: 0 24px;
  p {
    align-items: center;
    color: $blank;
    display: flex;
  }
  .badge {
    align-items: center;
    background-color: $logo;
    border-radius: 50%;
    color: $blank;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    margin-right: 8px;
    justify-content: center;
    width: 32px;
  }
}

:global(.impersonate) {
  .header-bar {
    display: flex;
  }
}

.header {
  align-items: center;
  border-bottom: $border solid 1px;
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 0 24px;
  position: relative;
  z-index: 1;

  .logo-container {
    display: flex;
    align-items: center;
    
    .nav-logo {
      height: 40px;
    }
  }

  span.color {
    color: $logo;
    font-weight: 500;
    font-size: 18px;
    padding-left: 12px;
  }
}

//Right block of header
.blocks {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  //Help before account
  .help {
    align-items: center;
    color: $header;
    display: flex;
    margin-right: 20px;
    &::before {
      background: url('../../images/help.svg');
      content: '';
      display: block;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      width: 18px;
    }
  }
}

//Account
.block {
  .account {
    align-items: center;
    color: $header;
    cursor: pointer;
    display: flex;
    &::after {
      background: url('../../images/arrow-down.svg');
      content: '';
      display: block;
      flex-shrink: 0;
      height: 4px;
      margin-left: 8px;
      width: 8px;
    }
    .badge {
      align-items: center;
      background-color: $logo;
      border-radius: 50%;
      color: $blank;
      display: flex;
      flex-shrink: 0;
      height: 32px;
      margin-right: 8px;
      justify-content: center;
      width: 32px;
    }
    &:hover {
      text-decoration: none;
      .badge {
        opacity: .7;
      }
    }
  }
}

.options {
  background-color: $blank;
  border: $border solid 1px;
  border-radius: $radius;
  position: absolute;
  right: 0;
  z-index: 10;
  ul {
    padding: 4px 0;
  }
  li {
    cursor: pointer;
    padding: 4px 8px;
    white-space: nowrap;
    a {
      background-color: transparent;
      border-width: 0;
      color: $default;
      display: block;
      font-size: 14px;
      text-decoration: none;
    }
    &.active,
    &.active:hover {
      color: $blank;
      background-color: $primay;
    }
    &:hover {
      background-color: $hover-background;
    }
  }
}

