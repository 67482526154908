.main-content {
    :global(.FieldInput) {

        span,
        input {
            font-size: 14px;
        }
    }

    :global(s-select) {
        background-color: white;
    }
}

.blockIconWarning {
    background-color: var(--untitled-medium);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 11px;
}

.textTitle {
    color: var(--grayscale-dark);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.048px;
    margin-top: 14px;
}

.divider1 {
    margin-top: 15px;
    margin-bottom: 26px;
}