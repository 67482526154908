@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.top-section {
  border-bottom: $block solid 1px;
  margin-bottom: 20px;
}

.buttons {
  align-items: center;
  display: flex;
  border-top: $border solid 1px;
  padding: 24px 0;
  button,
  :global(.button) {
    margin-right: 20px;
  }
}

.results {
  padding-bottom: 20px;
  width: 902px;
  .mappedFileName {
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: unset;
    color: $black;
    text-transform: unset;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    padding-right: 8px;
    white-space: nowrap;
  }

  .mappedFileColumn1 {
    flex: 3 0;
  }

  .mappedFileColumn2 {
    flex: 1 0 114px;
  }

  .mappedFileColumn3 {
    flex: 1 0 104px;
  }

  .mappedFileColumn4 {
    flex: 1 0 116px;
  }

  .mappedFileColumn5 {
    flex: 1 0 90px;
  }
  
  .buttons {
    padding: 0 0 24px 0;
    :global(.default-button) {
      margin-left: 0;
      margin-right: 20px;
    }
  }
}