@import "../../style/variables";

// Block style
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin: 24px 0;
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3{
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    color: $default;
  }
}

.form {
  padding: 24px;
  .field {
    flex-grow: 1;
  }
  fieldset {
    border-width: 0;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  hr {
    color: $block;
  }
  label {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: $header;
  }
  .text {
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: $default;
    font-weight: bold;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .column {
    width: 100%;
    padding-bottom: 20px;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
    &.bordered-column {
      border-bottom: $block solid 1px;
    }
  }
  .cell {
    width: 48%;
  }
  .column-purchased {
    display: flex;
    justify-content: space-between;
    width: 48%;
    gap: 5px;
  }
  .cell-year {
    width: 40%;
  }
  // Reposition checkbox
  :global(.custom-checkbox) {
    padding: 0 0 24px 0;
    label {
      font-size: 15px;
    }
  }
  .businessNameField {
    width: 100%;
  }
}

.add-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  &::before {
    background: url("../../images/icon-add.svg") no-repeat center center;
    content: "";
    display: block;
    height: 16px;
    margin-right: 12px;
    width: 16px;
  }
}

.operate {
  align-items: center;
  display: flex;
  gap: 10px;
  :global(.default-button) {
    margin-left: 20px;
  }
  .delete {
    background: url("../../images/icon-remove.svg") no-repeat center center/
      16px 16px;
    display: block;
    height: 16px;
    width: 16px;
  }
  .edit {
    background: url("../../images/icon-edit.svg") no-repeat center center/ 16px
      16px;
    display: block;
    height: 16px;
    width: 16px;
  }

  .close {
    background: url("../../images/icon-close.svg") no-repeat center center/ 16px
      16px;
    display: block;
    height: 16px;
    width: 16px;
    margin-left: 10px;
    margin-top: 30px;
  }
}

// Main footer inlcude add link and buttons group
.footer {
  .add-link {
    align-items: center;
    color: $primay;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    margin-left: 24px;
    &::before {
      background: url("../../images/icon-add.svg") no-repeat center center;
      content: "";
      display: block;
      height: 16px;
      margin-right: 12px;
      width: 16px;
    }
  }
  button {
    margin-top: 24px;
    margin-right: 20px;
    margin-left: 0;
  }
}
