@import '../../style/variables';

.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  //Title of section
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 20px 24px;
  }
  p {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    &::before {
      background: url('../../images/icon-learn.svg') no-repeat center center;
      background-color: $logo;
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }
  }
  .block {
    border-top: $block solid 1px;
    padding: 20px 24px 15px 24px;
    //Help list
    ul {
      padding-left: 48px;
    }
    li {
      padding: 5px 0;
      a {
        color: $primay;
        font-size: 14px;
        font-weight: 600;
      }
    }
    &.help {
      p {
        &::before {
          background-image: url('../../images/icon-micro.svg');
        }
      }
    }
  }
}
