@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.tab-content {
  padding-top: 24px;
}

.buttons {
  display: flex;
  padding: 24px 0;
  button,
  :global(.button) {
    margin-right: 20px;
  }
}