@import '../../style/variables';
@import '../../style/mixins';

@include formControl();

.form-control {
  
  //The input field
  .input-control {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    min-height: 36px;
    position: relative;
    input {
      background-color: transparent;
      border-width: 0;
      color: $default;
      font-size: 14px;
      height: 34px;
      padding: 6px 12px;
      width: 100%;
      &:focus {
        box-shadow: $focus-color 0 0 0 2px;
      }
    }
    // Disabled status
    &:global(.disabled) {
      color: $default;
      background-color: $disabled;
      opacity: 0.6;
    }
    &.with-icon {
      align-items: center;
      display: flex;
      &::before {
        background: url('../../images/icon-search.svg') no-repeat center center;
        content: '';
        display: block;
        flex-shrink: 0;
        height: 16px;
        margin-right: 10px;
        width: 16px;
      }
    }
  }
  /* Error status */
  &:global(.error) {
    .input-control {
      background-color: $error-background;
      border-color: $error-border;
    }
  }
}

.options {
  background-color: $blank;
  border: $border solid 1px;
  border-radius: $radius;
  position: absolute;
  max-height: 164px;
  margin: 0 -1px;
  top: 35px;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10;
  ul {
    padding: 4px 0;
  }
  li {
    color: $default;
    cursor: pointer;
    font-size: 14px;
    padding: 4px 12px;
    &.active,
    &.active:hover {
      color: $blank;
      background-color: $primay;
    }
    &:hover {
      background-color: $hover-background;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();
