.flex-1 {
    flex: 1 1 0%;
    width: 1px;
}

.flex-none {
    flex: none;
}

.gap-30 {
    gap: 30px;
}

.gap-20 {
    gap: 20px;
}

.gap-16 {
    gap: 16px;
}

.gap-12 {
    gap: 12px;
}

.gap-10 {
    gap: 10px;
}

.d-flex {
    display: flex;

    &.flex-wrap {
        flex-wrap: wrap;
    }

    &.align-items- {
        &center {
            align-items: center;
        }

        &stretch {
            align-items: stretch;
        }

        &end {
            align-items: flex-end;
        }

        &start {
            align-items: flex-start;
        }
    }

    .align-self- {
        &center {
            align-self: center;
        }

        &start {
            align-self: flex-start;
        }

        &end {
            align-self: flex-end;
        }
    }

    &.justify-content- {
        &center {
            justify-content: center;
        }

        &end {
            justify-content: flex-end;
        }

        &between {
            justify-content: space-between;
        }

        &around {
            justify-content: space-around;
        }
    }

    &.flex-column {
        flex-direction: column;
    }

    &.flex-row {
        flex-direction: row;
    }
}