@import "../../../../style/variables";

.bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  min-height: 53px;
  min-width: 1300px;

  button:not(:first-child) {
    margin-left: 20px;
  }
}

.bar-right {
  display: flex;
  align-items: center;
}

.chips {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4c4c4;
}

.selected-items-action {
  display: flex;
  flex: none;
  align-items: center;
  gap: 20px;

  > * {
    margin: 0;
    flex: none;
  }

  > label {
    font-size: inherit;
  }

  .field-select {
    width: 300px;
    flex: 0 1 auto;
  }
}

.tableLayout {
  min-width: 1300px;
}

.pagination {
  justify-content: flex-end;
  display: flex;
}

.filters {
  max-width: 400px;

  .header {
    align-items: center;
    border-bottom: $block solid 1px;
    justify-content: space-between;
    display: flex;
    margin: 0 20px 5px 20px;
    padding: 16px 0;
    h3 {
      align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      &::before {
        background: url("../../../../images/icon-filter-circle.svg") no-repeat
          center center/ 24px 24px;
        content: "";
        display: block;
        height: 24px;
        margin-right: 12px;
        width: 24px;
      }
    }
    .arrow {
      align-items: center;
      border-left: $border solid 1px;
      display: flex;
      height: 24px;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 20px;
      &::before {
        background: url("../../../../images/arrow-right.svg") no-repeat center
          center/8px 12px;
        content: "";
        display: block;
        height: 12px;
        width: 8px;
      }
    }
  }
  .body {
    margin: 0 20px;
    & > ul > li {
      border-bottom: $block solid 1px;
      padding: 10px 0;
    }

    label {
      display: block;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 9px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 36px 20px;
  }
}
