.main-content {
    flex: 1 1 0%;
    width: 100px;
    padding: 0 0 0 23px;

    :global(.FieldInput) {
        margin-bottom: 0;
    }

    :global(label:not(:first-child)) {
        margin-top: 0;
    }
}

.Breadcrumb {
    margin-top: 73px;
}

.textTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-top: 5px;
}

.blockLeft {
    margin-right: 40px;
    padding-bottom: 66px;
}

.divider1 {
    margin-top: 16px;
    margin-bottom: 25px;
}

.AssessorTaxYearForm {
    margin-top: 27px;
}