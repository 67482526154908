@import '../../style/variables';

.table-layout {
  padding-top: 23px;
  table {
    th {
      &:nth-child(6) {
        width: 200px;
      }
      &:last-child {
        width: 100px;
      }
    }
  }
}

.icon-3-dots {
  background: url(../../images/ic-3-dots.svg) no-repeat center;
  display: inline-block;
  width: 17px;
  height: 20px;
}
