@import "../../../../style/variables";

.main {
  [data-col="numberOfAccounts"] {
    text-align: center;
    width: 120px;
  }

  [data-col="actions"] {
    width: 50px;
    text-align: center;

    &:last-of-type {
      padding-left: 145px;
    }
  }
}

.edit {
  margin-right: 0 !important;
}
