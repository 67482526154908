@import '../../style/variables';

.table-menu {
  position: relative;
}

.menu {
  width: 201px;
  z-index: 10;
  background-color: $blank;
  border-radius: 4px;
  border: 1px solid $border;
  box-shadow: 0 2px 10px rgba($color: $black, $alpha: 0.16);
}

.menu-item-group {
  width: 100%;
  padding: 12px 4px;
}

.menu-item {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 12px;
  text-align: start;
  &.primary {
    color: rgba($color: $primay, $alpha: 1);
  }
  &.secondary {
    color: rgba($color: $default, $alpha: 0.5);
  }
}

.divider {
  margin: 0 16px;
}

.floating-overlay {
  z-index: 1000;
}