@import "../../style/variables";

.main-content {
  padding: 70px 40px 24px 24px;
  flex-grow: 1;
  h3 {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
    font-weight: 600;
  }
  
  :global(.table-layout) {
    table th {
      &:nth-child(1){
        width: 40%;
      }
      &:nth-child(2){
        width: 45%;
      }
      &:nth-child(3){
        width: 15%;
      }
    }
  }
  .footer {
    .add-link {
      align-items: center;
      color: $primay;
      display: flex;
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      margin-left: 24px;
    }
    .buttons {
      margin-top: 24px;
      :global(.primary-button) {
        margin-right: 16px;
      }
    }
  }
}
