@import '../../style/variables';

.content {
  background-color: $geryer-background;
  border-left: $border solid 1px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 1px;
  padding: 15px 20px;
  width: 270px;
  height: 100%;
  .block {
    padding-bottom: 15px;
  }
  // Resouces title
  .header-container{
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border;
  }
  .title {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    h3 {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
    }
  }
  a {
    align-items: center;
    border-left: $border solid 1px;
    display: flex;
    height: 24px;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-bottom: 16px;
    width: 20px;
    &::before {
      background: url('../../images/arrow-right.svg') no-repeat center
        center/8px 12px;
      content: '';
      display: block;
      height: 12px;
      width: 8px;
    }
  }
  .selection-header{
    color: $header;
  }
}
