@import '../../style/variables';

.bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.operate {
  align-items: center;
  display: flex;
  gap: 20px;
  // order: 10;
}

td .operate {
  gap: 0;
}

.table-layout {
  overflow: auto;
  width: 100%;
  th {
    &:first-child {
      width: 20px;
    }
    &:last-child {
      width: 40px;
    }
  }
}

.pagination {
  border-bottom: $border solid 1px;
  justify-content: flex-end;
  display: flex;
}

.rollover {
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 150px;
  &::before {
    background: url('../../images/icon-rollover.svg') no-repeat center center;
    content: '';
    display: block;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -11px;
    width: 22px;
  }
  .block {
    display: block;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 50px;
    small {
      display: block;
    }
  }
}

.disposal-note {
  font-size: 12px;
  font-style: italic;
  display: flex;
  justify-content: center;
}

.selected-items-action {
  display: flex;
  flex: none;
  align-items: center;
  gap: 20px;

  > * {
    margin: 0;
    flex: none;
  }

  > label {
    font-size: inherit;
  }

  .field-select {
    width: 300px;
    flex: 0 1 auto;
  }

  .batch-action-buttons {
    button {
      margin-right: 10px;
    }
  }
}