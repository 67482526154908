@import './variables';

/* Reset HTML5
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'SourceSansPro', Arial, Helvetica, sans-serif;
  font-style: normal;
}
html {
  color: $default;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

div {
  box-sizing: border-box;
}

.main-layout {
  h2 {
    font-weight: bold;
  }
}

// Reset input style
input {
  color: $default;
  font-size: 14px;
  font-family: 'SourceSansPro', Arial, Helvetica, sans-serif;
  line-height: 20px;
  &:focus {
    outline: none;
  }
}

// Restyle auto fill of input
input:-internal-autofill-selected {
  background-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

input::placeholder {
  color: $date;
}

input.decimal {
  text-align: right;
}

// Button style
button {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  margin: 0;
  list-style: none;
  font-family: 'SourceSansPro', Arial, Helvetica, sans-serif;
  font-style: normal;
}

.primary-button {
  background-color: $primay;
  border: $primay solid 1px;
  border-radius: 4px;
  color: $blank;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  padding: 0 20px;
  text-decoration: none;
  white-space: nowrap;

  &:disabled {
    background-color: $geryer-background;
    border: $border solid 1px;
    color: $label;
    cursor: default;
    opacity: .5;
  }

  &:visited {
    color: $blank;
  }
}

.default-button {
  background-color: $geryer-background;
  border-radius: 4px;
  border: $border solid 1px;
  color: $default;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 0 20px;
  text-decoration: none;
  white-space: nowrap;
  &:disabled {
    background-color: $geryer-background;
    border: $border solid 1px;
    color: $label;
    cursor: default;
    opacity: .5;
  }
}

.disabled-button {
  background-color: $geryer-background;
  border-radius: 4px;
  border: $border solid 1px;
  cursor: default;
  color: $default;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  padding: 0 20px;
  text-decoration: none;
}

.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

// Custom checkbox
.custom-checkbox {
  label {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 18px;
  }
  input {
    display: none;
    & + span {
      background: url('../images/checkbox.svg');
      display: block;
      flex-shrink: 0;
      height: 20px;
      margin-right: 12px;
      width: 20px;
    }
    &:checked + span {
      background-image: url('../images/checkbox-checked.svg');
    }
  }
  &.no-space {
    input {
      & + span {
        margin-right: 0;
      }
    }
  }
}

// Custom radio
.custom-radio {
  label {
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 19px;
  }
  input {
    display: none;
    & + span {
      background: url('../images/icon-radio.svg');
      display: block;
      flex-shrink: 0;
      height: 20px;
      margin-right: 12px;
      width: 20px;
    }
    &:checked + span {
      background-image: url('../images/icon-radio-active.svg');
    }
  }
}

i[role='button'],
a[role='button'],
div[role='button'] {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.5s;
  }
}

// Restyle selected day of day picker
.rdp-day_selected:not([disabled]),
.rdp-day_selected:focus:not([disabled]),
.rdp-day_selected:active:not([disabled]),
.rdp-day_selected:hover:not([disabled]) {
  border-width: 0;
  background-color: $primay;
}

s-popper[data-popper-escaped][type='tooltip'] {
  visibility: visible;
}