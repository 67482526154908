@import '../../style/variables';

.container {
  :global(s-uploader) {
    div[data-ref='guidelines-wrapper'] {
      width: 100%;
      h3 {
        margin-bottom: 0 !important;
      }
    }
    div[data-ref='dropzone'] {
      border-width: 1px;
      border-radius: 8px;
    }
  }
  :global(.non-filled) {
    div[data-ref='guidelines-wrapper'] {
      & > button {
        background-color: $primay;
        color: $blank;
      }
    }
  }
}

.placeholder {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  margin: 15px 0;
  text-align: center;
  a {
    color: $primay;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  small {
    color: $header;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    padding-top: 11px;
  }
}
.list {
  border-top: $block solid 1px;
  margin-bottom: 42px;
  width: 100%;
  li {
    align-items: center;
    border-bottom: $block solid 1px;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
  p {
    align-items: center;
    display: flex;
    font-size: 14px;
    line-height: 18px;
  }
  .icon {
    background: url('../../images/icon-xlsx.svg') no-repeat center center;
    display: block;
    flex-shrink: 0;
    height: 20px;
    margin-right: 12px;
    width: 20px;
  }
}

.buttons {
  justify-content: space-between;
  display: flex;
  padding: 24px 0 32px 0;
  :global(.default-button) {
    margin-right: 24px;
  }
}

.action {
  position: absolute;
  right: 0;
  padding-top: 12px;
}