$primay: #117BAB;
$primary-logo: #992E0E;
$logo: #FD6620;
$default: #1A1A1A;
$geryer-background: #FAFAFA;
$border: #C4C4C4;
$border-1: #707070;
$error-border: #C70000;
$error-background: #FFFAFA;
$blank: #FFFFFF;
$black: #000;
$modal-border: #707070;
$header: #6F6F6F;
$pdf-main-background: #EFEFEF;
$hover-background: #CFEDFA;
$hover-color: #074F70;
$radius: 4px;
$block: #DDDDDD;
$successful: #39A632;
$active: #F7FDFF;
$label: #1F1F1F;
$today: #CFEDFA;
$date: #999999;
$radius-small: 2px;
$radius-large: 8px;
$radius: 4px;
$error-message: rgba(255, 0, 0, .1);
$disabled: #EEEEEE;
$active-background: #288125;
$success: #288125;
$error: #C50813;
$warning: #FEDA5A;
$normal: #D0EDF9;
$ready: #A0E59D;
$modal-mask: rgba(0, 0, 0, .5);
$selected: #074f70;
$shadow: rgba(0, 0, 0, .16);
$light-grey: #F4F5F6;
$focus-color: #0c7bab;

:root {
    --untitled-medium: #FEDA5A;
    --red-dark: #C50913;
    --blue-dark: #177CA9;
    --grayscale-dark: #6F6F6F;
}