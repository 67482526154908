@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.buttons {
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.top-bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top-bar {
  width: 100%;
  align-items: center;
  display: flex;
  min-height: 62px;
}

.bar-left {
  display: flex;
  align-items: flex-end;
}

.filter-group {
  width: 300px;
  margin-right: 20px;
}

.bar-right {
  margin-left: auto;
  display: flex;
  align-items: flex-end;
  &>:not(:first-child) {
    margin-left: 20px;
  }
}

.pagination {
  justify-content: flex-end;
  display: flex;
}

.info-row {
  background-color: #FFD1B2;
  border: #FF6600 solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 24px;
}

.info-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:not(:first-child) {
    margin-left: 64px;
  }
}

.info-field-label {
  font-size: 12px;
  line-height: 15px;
  color: $header;
  text-transform: uppercase;
}

.info-field-value {
  margin-top: 2px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.item-select-field {
  width: 190px;
}

.selected-items-action {
  margin-top: 20px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  border-top: 1px solid $border;
  .selected-items {
    margin-top: 43px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
  .group-field-select {
    width: 180px;
    margin-left: 16px;
  }
  .group-field-toggle {
    margin-top: 43px;
    margin-left: 20px;
  }
  .batch-action-fields {
    align-items: center;
    display: flex;
  }
  .batch-action-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-top: 18px;
    &>:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.icon-3-dots {
  background: url('../../../images/ic-3-dots.svg') no-repeat center;
  display: inline-block;
  width: 17px;
  height: 20px;
}