@import '../../style/variables';

.form-control {
  align-items: center;
  border: 1px solid $border;
  background-color: $blank;
  border-radius: $radius;
  display: flex;
  height: 34px;
  padding: 0 12px;
  width: 300px;
  // Search icon
  i {
    background: url('../../images/icon-search.svg') no-repeat center center;
    display: block;
    flex-shrink: 0;
    height: 16px;
    margin-right: 12px;
    width: 16px;
  }
  // Search input field
  input {
    border-width: 0;
    background-color: transparent;
    color: $default;
    font-size: 14px;
    padding: 0;
    height: 20px;
    width: 100%;

    &::placeholder {
      font-style: normal;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  &:focus-within {
    border: 1px solid $focus-color;
    box-shadow: 0 0 0 1px $focus-color;
  }
}

:global(.guideline) {
  .form-control {
    width: 100%;
  }
}