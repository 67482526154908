@import '../../style/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 16px 0;
  border-top: 1px solid $block;
}

.btnAdd {
  order: 10;
}

.operate {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  :global(.default-button) {
    margin-left: 20px;
  }
  :global(.primary-button) {
    margin-left: 20px;
  }
}

.emptyLine {
  height: 8px;
}

.table-layout {
  width: 100%;
  th[data-col='actions'] {
    width: 40px;
  }
}

.pagination {
  border-bottom: $border solid 1px;
  justify-content: flex-end;
  display: flex;
}

.fieldInput {
  margin-bottom: 16px;
}

.buttons {
  justify-content: flex-start;
  display: flex;
  padding-top: 12px;
  button {
    margin: 0 20px 0 0;
  }
}

.filters {
  .header {
    align-items: center;
    border-bottom: $block solid 1px;
    justify-content: space-between;
    display: flex;
    margin: 0 20px 5px 20px;
    padding: 16px 0;
    h3 {
      align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      &::before {
        background: url('../../images/icon-filter-circle.svg') no-repeat center
          center/ 24px 24px;
        content: '';
        display: block;
        height: 24px;
        margin-right: 12px;
        width: 24px;
      }
    }
    .arrow {
      align-items: center;
      border-left: $border solid 1px;
      display: flex;
      height: 24px;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 20px;
      &::before {
        background: url('../../images/arrow-right.svg') no-repeat center
          center/8px 12px;
        content: '';
        display: block;
        height: 12px;
        width: 8px;
      }
    }
  }
  .body {
    margin: 0 20px;
    & > ul > li {
      border-bottom: $block solid 1px;
      padding: 10px 0;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 36px 20px;
  }
}

.add-user-button {
  order: 1;
}

.error {
  font-size: 14px;
  color: #C70000;
  margin-top: 12px;
}

.modal {
  border: $block solid 1px;
  border-radius: $radius-large;
  background-color: $blank;
}

.modal-wrapper {
  padding: 40px;
  h3 {
    margin-bottom: 25px;
  }
  .add-edit-user-modal {
    width: 300px;
  }
  .form {
    padding: 0;
  }
  .section {
    border: none;
  }
}

.innerModal {
  border: solid 1px #000;
}