@import '../../style/variables';

.admin-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

.admin-content {
  padding: 30px 20px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.selection-container {
  background-color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 5px;
  padding: 20px;
  width: 220px;
  flex-grow: 1;
  flex-shrink: 0;
}
