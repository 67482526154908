@import '../../style/variables';

.form-control {
  display: block;
  //Label for input field
  .label {
    align-items: center;
    display: flex;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 9px;
  }
  //required marker '*'
  .required {
    color: $error-border;
    margin-left: 2px;
  }
  //tooltip for the input field
  .tooltip {
    position: relative;
    margin-left: 8px;
    &::before {
      background: url('../../images/icon-info.svg') no-repeat center center;
      content: '';
      display: block;
      height: 12px;
      width: 12px;
    }
    .tip {
      background-color: $blank;
      border: $border solid 1px;
      border-radius: $radius;
      display: none;
      padding: 8px 12px;
      position: absolute;
      top: -12px;
      left: 14px;
      white-space: nowrap;
    }
    /* Hover on the tooltip,tip appear */
    &:hover {
      .tip {
        display: block;
      }
    }
  }

  //The input field
  .input-control {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    position: relative;
    min-height: 36px;

    &:focus {
      border: 1px solid var(--color-blue-dark);
      box-shadow: 0 0 0 1px var(--color-blue-dark);
      outline: none;
    }

    //dropdown icon
    &::after {
      background: url('../../images/icon-dropdown.svg') no-repeat center center;
      content: '';
      display: block;
      height: 4px;
      margin-top: -2px;
      top: 50%;
      position: absolute;
      right: 12px;
      width: 8px;
    }
    > div {
      &:first-child {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .selected {
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
    display: block;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.default {
      color: $header;
    }
  }
  .options {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    position: absolute;
    max-height: 164px;
    margin: 0 -1px;
    top: 34px;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;
    ul {
      padding: 4px 0;
    }
    li {
      color: $default;
      cursor: pointer;
      font-size: 14px;
      padding: 4px 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 5px;

      &.active {
        background-color: var(--color-blue-lighter);
        box-shadow: 0 0 0 2px var(--color-white), 0 0 0 4px var(--color-gray-dark);
        border-radius: 4px;
      }
      &:hover {
        background-color: var(--color-gray-lightest);
      }

      &.noMatch {
        background-color: transparent;
        cursor: auto;
      }
    }
  }
  /* Error status */
  &:global(.error) {
    .input-control {
      background-color: $error-background;
      border-color: $error-border;
    }
  }
  &.disabled {
    .input-control {
      cursor: default;
      color: $default;
      background-color: $disabled;
      opacity: 0.6;
    }
  }
}

//Error message below the input field
.error-message {
  display: flex;
  font-size: 14px;
  color: $error-border;
  margin: 8px 0;
  &::before {
    background: url('../../images/icon-error.svg') no-repeat center center/16px
      16px;
    content: '';
    display: block;
    flex-shrink: 0;
    height: 16px;
    margin-top: 1px;
    margin-right: 8px;
    width: 16px;
  }
}

.floating-overlay {
  z-index: 1000;
}

.search-container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  min-width: fit-content;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-light);
  border-radius: 4px 4px 0 0;
  padding: var(--padding-xs);
  display: flex;
}