@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
  .main {
    padding-top: 8px;
  }
}

.buttons {
  border-top: $block solid 1px;
  padding: 24px 0;
  :global(.button) {
    margin-right: 20px;
  }
}

.refresh {
  padding-bottom: 12px;
}