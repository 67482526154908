@import "../../style/variables";

.form {
  padding: 24px;
  width: 100%;

  .column {
    width: 100%;
    padding-bottom: 20px;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
  }

  .cell {
    width: calc(30% - 10px);
  }
}

.add-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 15px 0;
  &::before {
    background: url("../../images/icon-add.svg") no-repeat center center;
    content: "";
    display: block;
    height: 16px;
    margin-right: 4px;
    width: 16px;
  }
}