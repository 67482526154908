@import "../../style/variables";
@import "../../style/mixins";

@include formControl();

.form-control {
  //The input field
  .input-control {
    background-color: $blank;
    border-radius: $radius;
    min-height: 36px;
    display: flex;
    input {
      padding: 0 12px;
      &:global(.error) {
        background-color: $error-background;
      }
    }
    &.with-icon {
      position: relative;
      input {
        padding-left: 38px;
      }
      .icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        align-items: center;
        z-index: 1;
      }
    }
  }

  input::placeholder {
    font-style: normal;
  }
}

//Error message below the input field
@include formControlErrorMessage();
