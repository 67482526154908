@import "../../style/variables";

.root {
  display: flex;
  overflow: hidden;
}

.pdf-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $pdf-main-background;
  height: 100%;
  overflow: hidden;
}

.pdf-main-page-container {
  width: 100%;
  overflow: auto;
}

.pdf-main-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
}

.pdf-main-page {
  margin: 26px;
  box-shadow: 0 10px 20px rgba($color: $black, $alpha: 0.16);
}

.pdf-preview-container {
  flex: none;
  height: 100%;
  overflow: auto;
  padding: 55px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $geryer-background;
}

.pdf-preview-item {
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 20px;
  }
  
  &.active {
    .pdf-preview-page-wrapper {
      border-color: $primay;
    }
    .pdf-preview-name {
      color: $selected;
    }
  }

  img {
    width: 100%;
  }
}

.pdf-preview-page-wrapper {
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid $block;
}

.pdf-preview-name {
  width: 100%;
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdf-main-header {
  margin: 34px 60px 0;
  display: flex;
  align-items: center;
}

.pdf-file-name {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.pdf-file-name-text {
  margin-left: 12px;
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-counter-name-placeholder {
  flex: 1;
}

.pdf-actions {
  flex: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
}

.pdf-pagination {
  display: flex;
  align-items: center;
}

.pdf-pagination-input {
  width: 46px;
  margin: 0 10px 0 16px;
}

.pdf-zoom {
  margin-left: 29px;
  display: flex;
  align-items: center;
}

.pdf-zoom-select {
  width: 85px;
  margin-left: 16px;
}

.pdf-rotate {
  margin-left: 15px;

  button.rotate {
    padding: 10px;
    i::before {
      background: url("../../images/icon-in-progress-white.svg") no-repeat center;
      content: "";
      display: block;
      height: 14px;
      width: 14px;
    }
  }
}