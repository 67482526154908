@import '../../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.content {
  display: flex;
  flex-direction: row;
}

.bar {
  width: 220px;
  margin-right: 60px;

  .bar-item-container {
    background-color: $geryer-background;
    padding: 20px;

    .barHeader {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
    }
  
    .barItem {
      font-size: 14px;
      line-height: 19px;
      margin-top: 16px;
      cursor: pointer;
      &.active {
        color: $hover-color;
        font-weight: 600;
      }
    }
  }
}

.rightPart {
  flex: 1;
}