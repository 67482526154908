@import '../../../style/variables';

.dashboard-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
  // Main container
  .main {
    align-items: flex-start;
    display: flex;
  }

  // Left sidebar
  .sider {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 32px;
    width: 384px;
  }
}

.primary-content {
  display: flex;
  // Main title
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
  .main {
    flex-grow: 1;
    padding: 30px 40px 24px 24px;
  }

  .section {
    margin-bottom: 32px;
    // Bar of section
    .bar {
      align-items: center;
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;
      &.dates-bar {
        padding-bottom: 16px;
      }
      h3 {
        align-items: center;
        display: flex;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        span.color {
          color: $logo;
          font-weight: 500;
          padding-left: 12px;
          &::before {
            color: $block;
            content: '|';
            font-weight: 600;
            margin-right: 12px;
          }
        }
      }
      p {
        color: $header;
        display: none;
        font-size: 12px;
        line-height: 16px;
      }
    }
    // Reset swiper prev & next button
    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
      background-image: url('../../../images/icon-slider.svg');
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      display: block;
      height: 16px;
      position: absolute;
      right: 0;
      top: -36px;
      width: 16px;
      z-index: 10;
    }
    :global(.swiper-button-prev) {
      right: 32px;
      transform: rotate(180deg);
    }
    /* Disabled status */
    :global(.swiper-button-disabled) {
      opacity: 0.5;
      cursor: default;
    }
    .content {
      display: flex;
    }
    .picker {
      flex-shrink: 0;
      flex-grow: 0;
      width: auto;

      .deadline-calendar {
        .deadline:not(:global(.react-calendar__month-view__days__day--neighboringMonth)) {
          background-color: $logo;
          color: $block;
          opacity: 1;
        }
      
        :global(.react-calendar__tile) {
          pointer-events: none;
        }

        :global(.react-calendar__navigation) {
          height: auto;
          margin-bottom: auto;
        }

        :global(.react-calendar__month-view__days__day--weekend) {
          color: inherit;
        }

        :global(.react-calendar__month-view__days__day--neighboringMonth) {
          color: $block;
        }
      }
    }
  }
}