@import "../../../style/variables";

.main-content {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  .main-wrapper{
    flex-grow: 1;
  }
  h4 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    margin: 14px 0;
    text-transform: uppercase;
  }
  li {
    font-size: 14px;
    line-height: 20px;
    color: $default;
    margin-bottom: 15px;
    cursor: pointer;
    &.selected {
      font-weight: 600;
      color: $selected;
    }
  }
}

.icon {
  background: url("../../../images/file.svg") no-repeat center center;
  background-color: $logo;
  border-radius: 50%;
  content: "";
  display: block;
  height: 24px;
  margin-right: 12px;
  width: 24px;
}