@import '../../style/variables';

.selection {
  .title{
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;    
    margin-bottom: 14px;
    text-transform: uppercase;
  }
  .item {
    font-size: 14px;
    line-height: 20px;
    color: #1a1a1a;
    &.selected {
      font-weight: 600;
      color: #074f70;
    }
    &:not(:first-child){
      cursor: pointer;
    }
  }
  .links{
    display: flex;
    flex-direction: column;
    gap: 15px;

    a{
      text-decoration: none;
    }
  }
}
