@import "../../../style/variables";

.main-content {
  display: flex;
  flex-grow: 1;
  .main-wrapper {
    flex-grow: 1;
    .form-container {
      padding: 80px 40px 24px 24px;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
          font-size: 18px;
          line-height: 25px;
          font-weight: bold;
          color: $default;
        }
      }
    }
  }
  li {
    font-size: 14px;
    line-height: 20px;
    color: $default;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: normal;
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: bold;
    }
    &.selected {
      font-weight: bold;
      color: $hover-color;
      .icon-arrow {
        transform: rotate(90deg);
      }
    }
    &.error {
      color: $error-border;
    }
    &:first-child {
      margin-top: 15px;
    }
    .icon-arrow {
      background: url("../../../images/arrow-right.svg") no-repeat center
        center/8px 12px;
      content: "";
      display: block;
      height: 12px;
      width: 8px;
    }
  }
  .categorySortItem {
    border-bottom: 1px solid $border;

    &.newPositionDragingTop {
      border-top: 2px solid $hover-color;
    }

    &.newPositionDragingBottom {
      border-bottom: 2px solid $hover-color;
    }
  }
  .listItemWrapper {
    font-size: 14px;
    line-height: 20px;
    color: $default;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: normal;

    &.newPositionDragingTop {
      border-top: 2px solid $hover-color;
    }

    &.newPositionDragingBottom {
      border-bottom: 2px solid $hover-color;
    }

    .item {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    &.selected {
      font-weight: bold;
      color: $hover-color;
      .icon-arrow {
        transform: rotate(90deg);
      }
    }
    &.error {
      color: $error-border;
    }
    &:first-child {
      margin-top: 15px;
    }
    .icon-arrow {
      background: url("../../../images/arrow-right.svg") no-repeat center
        center/8px 12px;
      content: "";
      display: block;
      height: 12px;
      width: 8px;
      margin-right: 10px;
    }
    .help-content-title {
      max-width: calc(100% - 22px);
    }
  }
  .topics {
    padding-left: 30px;
  }
}

.icon {
  background: url("../../../images/file.svg") no-repeat center center;
  background-color: $logo;
  border-radius: 50%;
  content: "";
  display: block;
  height: 24px;
  margin-right: 12px;
  width: 24px;
}

.add-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 15px 0;
  &::before {
    background: url("../../../images/icon-add.svg") no-repeat center center;
    content: "";
    display: block;
    height: 16px;
    margin-right: 4px;
    width: 16px;
  }
}
