@import '../../style/variables';

$icons: properties, assets, forms, returns;

//Properties Assets Returns blocks
.blocks {
  align-items: center;
  display: flex;
  margin: 0 -12px;
  & > div {
    background-color: $blank;
    border: $block solid 1px;
    border-radius: $radius;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 12px;
    height: 220px;
    width: 25%;
  }
  .block {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
    i {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      text-align: center;
      &::before {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 72px 72px;
        content: '';
        display: block;
        height: 72px;
        margin-top: 16px;
        margin-bottom: 45px;
        width: 72px;
      }
      //Different icons of navigation item
      @each $icon in $icons {
        &.#{$icon} {
          &::before {
            background: url('../../images/icon-dash-#{$icon}.svg');
          }
        }
      }
    }
    //Disabled
    &:disabled,
    &.disabled {
      background-color: $geryer-background;
      cursor: default;
      opacity: 1;
      i {
        color: $border;
        //Different icons of navigation item
        @each $icon in $icons {
          &.#{$icon} {
            &::before {
              background: url('../../images/icon-dash-#{$icon}-disabled.svg');
            }
          }
        }
      }
    }
  }
}
