@import '../../style/variables';
@import '../../style/mixins';

@include formControl();

s-popper.datepicker [data-ref=header] {
  height: 2rem;
}

.container {
  :global(s-popper) {
    &.datepicker [data-ref=header] {
      height: 2rem;
    }
  }

  :global(s-datepicker) {
    div[data-ref='input-container'] {
      width: auto;
    }

    input {
      padding: 0 12px;
    }

    input[readonly] {
      opacity: 1;
    }

    s-box[data-ref='picker'] {
      width: 266px;
      top: calc(100% - 1px) !important;
      left: auto !important;

      div[data-ref='header'] {
        height: 36px;

        s-icon {
          font-size: 14px;
        }

        button[data-ref='toggle-picker'] {
          span {
            white-space: nowrap;
          }
        }
      }
    }

    div[data-ref='picker-elements'] {
      table {
        tr {
          th {
            height: 28px;
            width: 28px;
          }
          td {
            height: 28px;
            width: 28px;

            button {
              height: 28px;
              width: 28px;
            }
          }
        }
      }
    }

    div[data-ref='yearpicker'] > button {
      width: 108px;
    }

    div[data-ref='monthpicker'] > button {
      width: 108px;
      padding-right: 0px;
    }
  }

  &:global(.error) {
    input {
      border: $error solid 1px;
      background-color: $error-background;
    }
  }

  &.showCalender {
    width: 266px;
    height: 255px;

    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        display: block !important;
      }
    }
  }

  &.hideTextField {
    :global(s-datepicker) {
      div[data-ref='input-container'] {
        display: none !important;
      }
    }
  }

  &.hideActionBottom {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='picker-elements'] {
          border-bottom: none;
        }
        div[data-ref='footer'] {
          display: none !important;
        }
      }
    }
  }

  &.hideTodayButton {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='picker-elements'] {
          :global(.flex.pad-bottom-sm) {
            display: none !important;
          }
        }
      }
    }
  }

  &.hideWeekdaysLabel {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='picker-elements'] {
          table {
            thead {
              display: none;
            }
          }
        }
      }
    }
  }

  &.notAllowSelectDateInPicker {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='picker-elements'] {
          table {
            tbody {
              tr {
                td {
                  button {
                    pointer-events: none;
                  }

                  button[data-ref='date-focus'] {
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.highlightDisabledDays {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='picker-elements'] {
          table {
            tbody {
              tr {
                td {
                  button[disabled][data-date] {
                    background-color: $logo;
                    color: $block;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.hideSelectMonthYear {
    :global(s-datepicker) {
      s-box[data-ref='picker'] {
        div[data-ref='header'] {
          button[data-ref='toggle-picker'] {
            pointer-events: none;

            s-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

//Label for input field
.label {
  align-items: center;
  display: flex;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 9px;
  & > span,
  span[data-tip='React-tooltip'] {
    display: block;
    line-height: 16px;
  }
  &.label-recommended {
    justify-content: space-between;
  }
  small {
    color: $header;
    font-size: 12px;
    line-height: 1;
  }
}

.datepicker-wrapper {
  .clear-datepicker {
    position: relative;
    float: right;
    top: -31px;
    right: 37px;
    z-index: 1;
    cursor: pointer;
    font-size: 18px;

    &::before {
      content: 'x';
    }
  }
}

//required marker '*'
.required {
  color: $error-border;
  margin-left: 2px;
  line-height: 16px;
}
//tooltip for the input field
.tooltip {
  position: relative;
  margin-left: 8px;
  &::before {
    background: url('../../images/icon-info.svg') no-repeat center center;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
  }
  .tip {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    display: none;
    padding: 8px 12px;
    position: absolute;
    top: -12px;
    left: 14px;
    white-space: nowrap;
  }
  /* Hover on the tooltip,tip appear */
  &:hover {
    .tip {
      display: block;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();
