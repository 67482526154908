@import '../../style/variables';

.sider {
  background-color: $geryer-background;
  border-left: $border solid 1px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 1px;
  padding: 15px 20px;
  width: 270px;

  .block {
    margin-bottom: 15px;
    border-bottom: $block solid 1px;
  }

  .help-header {
    align-items: center;
    display: flex;
    padding-bottom: 10px;

    &::before {
      background: url('../../images/icon-learn.svg') no-repeat center center;
      background-color: $logo;
      border-radius: 50%;
      content: '';
      display: block;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }
  }

  // Resouces title
  .title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    div.topic-toggle {
      align-items: center;
      border-left: $border solid 1px;
      display: flex;
      height: 24px;
      justify-content: flex-end;
      flex-shrink: 0;
      margin-bottom: 16px;
      width: 20px;
      cursor: pointer;

      &.collapsed::before {
        background: url('../../images/arrow-down.svg') no-repeat center
          center/8px 12px;
        content: '';
        display: block;
        height: 12px;
        width: 8px;
      }

      &.expanded::before {
        background: url('../../images/arrow-up.svg') no-repeat center
          center/8px 12px;
        content: '';
        display: block;
        height: 12px;
        width: 8px;
      }
    }
  }

  h4 {
    align-items: center;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 16px;
  }

  // Resouces list
  li {
    a {
      align-items: center;
      display: flex;
      padding: 15px 0;
      text-decoration: none;
      &::after {
        background: url('../../images/arrow-right.svg') no-repeat center center;
        content: '';
        display: block;
        flex-shrink: 0;
        height: 9px;
        margin: 0 10px 0 10px;
        width: 6px;
      }
      span {
        color: $default;
        display: block;
        font-size: 14px;
        line-height: 19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.topic-title {
          font-weight: bold;
        }
      }
      .content {
        display: block;
        flex-grow: 1;
      }
      small {
        color: $header;
        display: block;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
