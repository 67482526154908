@import '../../../style/variables';



.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 16px 0;
  border-top: 1px solid $block;
}

.operate {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  :global(.default-button) {
    margin-left: 20px;
  }
  :global(.primary-button) {
    margin-left: 20px;
  }
}

.emptyLine {
  height: 8px;
}

.table-layout {
  width: 100%;
  th[data-col='actions'] {
    width: 40px;
  }
}

.pagination {
  border-bottom: $border solid 1px;
  justify-content: flex-end;
  display: flex;
}


.buttons {
  justify-content: flex-start;
  display: flex;
  padding-top: 12px;
  button {
    margin: 0 20px 0 0;
  }
}

.filters {
  .header {
    align-items: center;
    border-bottom: $block solid 1px;
    justify-content: space-between;
    display: flex;
    margin: 0 20px 5px 20px;
    padding: 16px 0;
    h3 {
      align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 600;
      &::before {
        background: url('../../../images/icon-filter-circle.svg') no-repeat center
          center/ 24px 24px;
        content: '';
        display: block;
        height: 24px;
        margin-right: 12px;
        width: 24px;
      }
    }
    .arrow {
      align-items: center;
      border-left: $border solid 1px;
      display: flex;
      height: 24px;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 20px;
      &::before {
        background: url('../../../images/arrow-right.svg') no-repeat center
          center/8px 12px;
        content: '';
        display: block;
        height: 12px;
        width: 8px;
      }
    }
  }
  .body {
    margin: 0 20px;
    & > ul > li {
      border-bottom: $block solid 1px;
      padding: 10px 0;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 24px 20px 36px 20px;
  }
}

.modal {
  border: $block solid 1px;
  border-radius: $radius-large;
  background-color: $blank;
}

.innerModal {
  border: solid 1px #000;
}

.main-content {
  padding: 80px 40px 24px 24px;
  flex-grow: 1;
  h3 {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 20px;
    font-weight: 600;
  }

  [data-col]:first-child {
    padding-left: 20px;
  }
 
  [data-col="actions"] {
    width: 100px;

    &:last-of-type {
      padding: 0 40px 0 90px;
    }
  }
}

.icon-3-dots {
  background: url(../../../images/ic-3-dots.svg) no-repeat center;
  display: inline-block;
  width: 17px;
  height: 20px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}