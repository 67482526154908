@import "../../../style/variables";

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.form-content {
  padding: 24px 24px 8px;
  border: 1px solid $block;
  border-radius: 8px;
}

.section-wrapper {
  margin-top: 32px;
}

.question-list {
  margin-top: 8px;  
}

.question-wrapper {
  padding-left: 32px;
}

.question {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer {
  width: 500px;
}

.question-description {
  font-size: 14px;
  font-weight: 600;
  color: $default;
  margin-right: 70px;
}

.requiredMarker {
  color: $error-border;
  margin-left: 2px;
  line-height: 1;
}

.boolean-answer {
  flex: none;
  width: 190px;
}

.text-answer {
  flex: none;
  width: 455px;
}

.date-answer {
  flex: none;
  width: 190px;    
}

.checkboxs-wrapper {
  display: flex;
  flex-direction: column;
  flex: none;

  .checkbox-row + .checkbox-row {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  padding: 24px 0;
  :global(.button),
  button {
    margin-right: 20px;
  }
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: right;
  padding-bottom: 20px;
  .required {
    color: $error-border;
    font-size: 12px;
  }
}

.return-select {
  width: 500px;
  margin-bottom: 10px;
}