// Open Sans
@font-face {
	font-family: 'SourceSansPro'; 
	src: url('../../public/assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
	font-family: 'SourceSansPro'; 
	src: url('../../public/assets/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
	font-family: 'SourceSansPro'; 
	src: url('../../public/assets/fonts/SourceSansPro-Bold.ttf') format('truetype');
    font-weight: 700;
}