@import "../../style/variables";
@import '../../style/mixins';

@include formControl();

.form-control-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
}

.form-control {
  width: 100%;

  .input-control-wrapper {
    display: flex;
    gap: 20px;
  }

  //The input field
  .input-control {
    background-color: $blank;
    border: $border solid 1px;
    border-radius: $radius;
    padding: 8px 12px;
    min-height: 36px;
    flex-grow: 1;
    input {
      background-color: transparent;
      border-width: 0;
      color: $default;
      font-size: 14px;
      padding: 0;
      height: 20px;
      width: 100%;
      &:focus{
        border: none;
        box-shadow: none;
      }
    }
    // Disabled status
    &:global(.disabled) {
      color: $default;
      background-color: $disabled;
      opacity: 0.6;
    }
    &:focus-within {
      border: 1px solid $focus-color;
      box-shadow: 0 0 0 1px $focus-color;
    }
  }
  /* Error status */
  &:global(.error) {
    .input-control {
      background-color: $error-background;
      border-color: $error-border;
    }
  }
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .tag {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 3px 6px;
    background: $normal;
    color: $label;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    border: $normal solid 1px;
    border-radius: $radius;
    text-transform: none;
    .close-icon{
      background: url('../../images/icon-close.svg') no-repeat center center;
      display: block;
      flex-shrink: 0;
      height: 10px;
      width: 10px;
      cursor: pointer;
    }
  }
}

//Error message below the input field
@include formControlErrorMessage();
