s-dialog.container {
  .header[slot="header"] {
    border-bottom: none;
    padding: 12px 0 0 0;
    min-height: 52px;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  div[data-ref="content-container"] {
    padding: 18px 30px;
    background-color: #FFF !important;
  }

  .footer[slot="footer"] {
    border-top: none;
    justify-content: center;
    padding: 10px 0 30px;
    :global(.buttons) {
      align-items: center;
      display: flex;
      button {
        margin: 0 8px;
      }
    }
  }

  &[open] {
    z-index: 1001;

    &::before {
      display: none;
    }

    + .backdrop {
      background: rgba(26, 26, 26, 0.5);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;

      &.innerModal {
        background: transparent;
      }
    }
  }
}
