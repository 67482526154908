@import '../../style/variables';

.progress {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  padding: 19px 0 16px 0;
  // Progress title
  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 24px 10px 24px;
  }
  // Prograss detail
  .detail {
    padding: 0 24px 13px 24px;
    dl {
      display: flex;
      dt,
      dd {
        font-size: 14px;
        line-height: 24px;
      }
      dt {
        flex-shrink: 0;
        margin-right: 4px;
      }
      dd {
        width: 60%;
        flex-grow: 1;
      }
    }
    :global(.default-button) {
      flex-shrink: 0;
      white-space: nowrap;
    }
  }
  //Prograss bar
  .bar {
    width: 382px;
    border-top: $block solid 1px;
    color: $header;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    padding: 12px 14px 2px 14px;
    text-align: right;
  }
  :global(.progress-bar) {
    margin-top: 6px;
  }
}
