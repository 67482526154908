@import '../../style/variables';

.breadcrumb {
  margin-bottom: 20px;

  :global(s-breadcrumbs) {
    
    nav ol[data-ref="breadcrumbs-expanded"] {
      font-size: 14px;
    }
  }
  a,
  span {
    font-weight: 600;
  }
  a:visited {
    color: $primay;
  }
}
