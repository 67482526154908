@import "../../../../style/variables";

.filter-panel {
  margin: 0;
  border: none;

  :global .wrapper {
    background: none;
  }
}

.group {
  border: 0;
  border-bottom: 1px solid #dddddd;
  padding: 16px 0 17px;

  .label,
  label {
    font-size: 14px;
    margin-bottom: 6px;
    display: block;
  }

  .groupLabel {
    color: #6f6f6f;
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 3px;

    ~ label,
    * label {
      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    + label {
      margin-top: 0 !important;
    }

    + :not(label) {
      margin-top: 6px;
    }
  }
}
