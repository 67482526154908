@import '../../../style/variables';
.main-content {
  padding: 30px 40px 24px 24px;
  // Main title
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

// Block header
.header {
  display: flex;
  padding: 24px 0;
  margin: 0 24px;
  justify-content: space-between;
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
  }
  // Remove and edit button
  .operate {
    align-items: center;
    display: flex;
  }
  .remove {
    background: url('../../../images/icon-delete.svg') no-repeat center center;
    display: block;
    height: 16px;
    margin-left: 20px;
    width: 16px;
  }
  .edit {
    background: url('../../../images/icon-edit.svg') no-repeat center center;
    display: none;
    height: 16px;
    margin-left: 20px;
    width: 16px;
  }
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  .required {
    color: $error-border;
    font-size: 12px;
  }
}

.toggle-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}

.form {
  margin: 0 24px;
  fieldset {
    border-width: 0;
    .buttons {
      padding-top: 0;
    }
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  .row {
    display: flex;
    margin: 0 -12px;
    padding-bottom: 4px;
    &.row-with-checkbox {
      padding-bottom: 0;
    }
  }
  .column {
    padding: 0 12px;
    padding-bottom: 20px;
    flex-shrink: 1;
    width: 33.333%;
    &.large-column {
      width: 66.667%;
    }
  }
  // Address or data row column style
  .address-row,
  .row-date {
    display: flex;
    margin: 0 -12px;
  }
  .address-row {
    margin: 0 -8px;
  }
  .address-column {
    margin: 0 8px;
    width: 30%;
    &:first-child {
      width: 45%;
    }
    &:last-child {
      width: 25%;
    }
  }
  .column-date {
    margin: 0 12px;
    width: 50%;
  }
  // Reposition checkbox & radio
  :global(.custom-radio) {
    padding: 0 0 10px 0;
  }
  :global(.custom-checkbox) {
    padding: 10px 0 0 0;
    label {
      font-size: 12px;
    }
  }
}

// Account form
.inner-account {
  .header {
    h3 {
      cursor: pointer;
    }
  }
  .form {
    border-bottom: $block solid 1px;
  }
  &.last {
    .form {
      border-bottom: 0;
    }
  }
  // Collapsed status of account form
  &.collapsed {
    &.last {
      margin-bottom: 20px;
    }
    .header {
      border-bottom: $block solid 1px;
      padding-top: 20px;
      padding-bottom: 20px;
      
      h3 {
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        display: flex;
        &::before {
          background: url('../../../images/icon-arrow-right.svg') no-repeat
            center center;
          content: '';
          display: block;
          height: 16px;
          margin-right: 12px;
          width: 16px;
        }
      }
      .operate {
        align-items: center;
      }
      .edit {
        display: block;
      }
      :global(.toggleCheckbox) {
        display: none;
      }
    }
    .form {
      display: none;
    }
  }
}

.block {
  border-bottom: $block solid 1px;
  padding-top: 24px;
  padding-bottom: 1px;
  &:first-child {
    padding-top: 0;
  }
}

.buttons {
  display: flex;
  padding-top: 10px;
  padding-bottom: 23px;
  button,
  :global(.button) {
    margin-right: 16px;
  }
  .add-button {
    align-items: center;
    display: flex;
    &::before {
      background: url('../../../images/icon-add-black.svg') no-repeat center
        center;
      content: '';
      display: block;
      height: 16px;
      margin-right: 10px;
      width: 16px;
    }
  }
}

// Add account link
.add-account {
  border-top: $block solid 1px;
  margin: 0 24px;
  padding-top: 24px;
}

.add-link {
  align-items: center;
  color: $primay;
  display: inline-flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  &::before {
    background: url('../../../images/icon-add.svg') no-repeat center center;
    content: '';
    display: block;
    height: 16px;
    margin-right: 12px;
    width: 16px;
  }
}

// Main footer inlcude add link and buttons group
.footer {
  .buttons {
    :global(.primary-button) {
      margin-right: 16px;
    }
  }
  .add-link {
    margin-left: 24px;
  }
}

/* Finish status */
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &.collapsed {
    padding-bottom: 0;
    .header {
      padding-top: 16px;
      padding-bottom: 16px;
      h3 {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        &::before {
          background: url('../../../images/icon-arrow-right.svg') no-repeat
            center center;
          content: '';
          display: block;
          height: 16px;
          margin-right: 12px;
          width: 16px;
        }
      }
      .operate {
        align-items: center;
      }
      .edit {
        display: block;
        &:last-child {
          margin-right: 0;
        }
      }
      :global(.toggleCheckbox) {
        display: none;
      }
    }
    .form,
    .inner-account,
    .add-account {
      display: none;
    }
  }
}

// Note below input field
.note {
  color: $header;
  font-size: 12px;
  line-height: 15px;
  padding-top: 12px;
  a {
    color: $primay;
    font-weight: bold;
  }
}

.list {
  background-color: $geryer-background;
  border-radius: $radius-large;
  justify-content: space-between;
  display: flex;
  padding: 16px;
  margin-bottom: 24px;
  .item {
    padding-top: 5px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    box-sizing: content-box;
    &:first-child {
      flex-shrink: 0;
      width: 74px;
    }
    &:last-child {
      padding-right: 0;
    }
    &:nth-child(6),
    &:nth-child(7) {
      max-width: 170px;
    }
    small {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span[data-tip='React-tooltip'] {
      color: $header;
      display: block;
      font-size: 12px;
      padding-bottom: 8px;
      text-transform: uppercase;
    }
  }
}

:global(.collapsed) {
  .list {
    .item {
      padding-right: 24px;
      &:first-child {
        width: 114px;
      }
    }
  }
}

@media (min-width: 1680px) {
  .list {
    .item {
      &:nth-child(6),
      &:nth-child(7) {
        max-width: 20%;
      }
    }
  }
}
