.main-content {
    flex: 1 1 0%;
    width: 100px;
    padding: 80px 40px 66px 23px;

    :global(.FieldInput) {
        margin-bottom: 0;
    }

    :global(label:not(:first-child)) {
        margin-top: 0;
    }
}

.textTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 16px;
}

.divider1 {
    margin-bottom: 20px;
}

.FormManagementSearch {
    margin-top: 22px;
    margin-bottom: 16px;
}

.divider2 {
    margin-top: 10px;
    margin-bottom: 40px;
}