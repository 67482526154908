@import '../../style/variables';
@import '../../style/mixins';

@include formControl();

.input-wrapper {
  position: relative;
  .readonly {
    background-color: $geryer-background;
    border-left: $border solid 1px;
    border-radius: 0 $radius $radius 0;
    font-size: 14px;
    line-height: 22px;
    padding: 6px 12px;
    width: 40%;
    box-sizing: border-box;
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cover {
    position: absolute;
    border-radius: $radius;
    background-color: $blank;
    top: 1px;
    left: 1px;
    bottom: 1px;
    font-size: 14px;
    padding: 6px 12px;
    width: calc(60% - 32px);
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(s-select) {
    font-size: 14px;

    div[data-ref='select-input-container'] {
      min-height: 36px;
    }

    span[data-ref='select-value'] {
      margin-left: 12px;
      max-width: 60%;
      visibility: hidden;
    }

    div[data-ref='disclosure-icon'] {
      width: 32px;
      height: 100%;
      right: 40%;
      s-icon {
        color: var(--color-gray-dark);
        font-size: 14px;
      }
    }

    :global(.contains-clear-button)[data-ref='select-input-container'] {
      padding-right: 36px;
    }

    [data-ref='deselect-all-button'] {
      display: none;
    }

    &:global(.error) div[data-ref="select-input-container"], 
    &:global(.error):hover div[data-ref="select-input-container"] {
      background-color: $error-background;
    }
  }
}

:global(.error) {
  .readonly,
  .cover {
    background-color: $error-background;
    border-color: $error-border;
  }
}

//Error message below the input field
@include formControlErrorMessage();
