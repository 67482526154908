@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  // Main title
  h2 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 25px;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  :global(.custom-checkbox) {
    padding-bottom: 25px;
    label {
      font-weight: 600;
    }
  }

  .addition-disposal-checkboxes {
    display: flex;
    justify-content: space-between;

    .margin-right {
      margin-right: 15px;
    }
  }
}

// Block style
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
  padding-top: 24px;
}

.form {
  padding: 0 24px 0 24px;
  fieldset {
    border-width: 0;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  .row {
    display: flex;
    margin: 0 -12px;
    padding-bottom: 4px;
  }
  .column {
    padding: 0 12px;
    padding-bottom: 20px;
    flex-shrink: 1;
    width: 33.333%;
    &.large-column {
      width: 66.667%;
    }
    &.small-column {
      width: 16.667%;
    }
    &.description-column {
      width: 40.98%;
    }
    &.taxYear-column {
      width: 23.04%;
    }
    &.originalCost-column {
      width: 17.99%;
    }
    &.address-column {
      width: 43.627%;
    }
    &.life-column {
      width: 6.363%;
    }
    &.half-column {
      width: 50%;
    }
    &.eq-column {
      width: 25%;
    }
    &.whole-column {
      width: 100%;
    }
    &.larger-column {
      width: 83.333%;
    }
  }
}

.buttons {
  display: flex;
  button,
  :global(.button) {
    margin-right: 16px;
  }
}

.bottom-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  .required {
    color: $error-border;
    font-size: 12px;
  }
}

.toggle-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
}

// Main footer inlcude add link and buttons group
.footer {
  .buttons {
    :global(.primary-button) {
      margin-right: 16px;
    }
  }
}

/* Finish status */
.asset-section {
  padding-bottom: 24px;
  &.last {
    padding-bottom: 0;
  }
  &.collapsed {
    .header {
      padding: 16px 24px;
      h3 {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 18px;
        &::before {
          background: url('../../../images/icon-arrow-right.svg') no-repeat
            center center;
          content: '';
          display: block;
          height: 16px;
          margin-right: 12px;
          width: 16px;
        }
      }
      .edit {
        display: block;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .section {
      margin-bottom: 0;
    }
    .form,
    .toggle-link {
      display: none;
    }
  }
}
