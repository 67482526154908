.main {
  [data-col="status"] {
    width: 140px;
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [data-col="actions"] {
    width: 60px;
    text-align: right;
  }

  :global(.operate) {
    justify-content: flex-end;
  }

  .name {
    align-items: center;
    display: flex;

    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .icon {
    background: url("../../images/icon-xlsx.svg") no-repeat center center;
    display: block;
    flex-shrink: 0;
    height: 20px;
    margin-right: 12px;
    width: 20px;
  }
}
