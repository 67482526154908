@import '../../../style/variables';

.main-content {
  padding: 30px 40px 24px 24px;
  h2 {
    font-size: 24px;
    line-height: 35px;
  }
}

.buttons {
  padding: 24px 0;
  :global(.default-button) {
    margin-left: 20px;
  }
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}