@import '../../style/variables';

// Steps
.steps {
    justify-content: center;
    display: flex;
    padding: 24px 0;
    ol {
      li {
        div[data-ref='step-label'] {
          padding: 0 45px;
        }
      }
    }
  }