@import "../../style/variables";

// Block style
.section {
  border: $block solid 1px;
  border-radius: $radius-large;
  margin-bottom: 24px;
}

.form {
  padding: 24px;
  width: 100%;
  fieldset {
    border-width: 0;
  }
  legend {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    padding-bottom: 12px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .column {
    width: 100%;
    padding-bottom: 20px;
    flex-shrink: 1;
    display: flex;
    justify-content: space-between;
  }
  .cell {
    width: calc(50% - 10px);
  }
  .column-row {
    width: 100%;
    padding-bottom: 20px;
    flex-shrink: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .column-purchased {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 10px);
  }
  .cell-year,
  .cell-amount {
    width: calc(50% - 10px);
  }
  // Reposition checkbox
  :global(.custom-checkbox) {
    padding: 0 0 24px 0;
    label {
      font-size: 15px;
    }
  }
  .businessNameField {
    width: 100%;
  }

  .column-dates {
    width: 60%;
  }
}

.add-link-wrapper {
  display: flex;
}

.add-link {
  align-items: center;
  color: $primay;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  &::before {
    background: url("../../images/icon-add.svg") no-repeat center center;
    content: "";
    display: block;
    height: 16px;
    margin-right: 12px;
    width: 16px;
  }
}

.operate {
  align-items: center;
  display: flex;
  margin-left: 20px;
  margin-top: 32px;
  .delete {
    background: url("../../images/icon-remove.svg") no-repeat center center/
      16px 16px;
    display: block;
    height: 16px;
    width: 16px;
  }
}

// Main footer inlcude add link and buttons group
.footer {
  .add-link {
    align-items: center;
    color: $primay;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    margin-left: 24px;
    &::before {
      background: url("../../images/icon-add.svg") no-repeat center center;
      content: "";
      display: block;
      height: 16px;
      margin-right: 12px;
      width: 16px;
    }
  }
  .buttons {
    margin-top: 24px;
    :global(.primary-button) {
      margin-right: 8px;
    }
  }
}

.confirmModal {
  right: 50% !important;
  bottom: 50% !important;

  :global([data-ref="content-container"]) {
    padding: 18px !important;
  }
}

.innerModal {
  border: solid 1px #000;
}

.error {
  span {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    color: #C70000;
    margin-top: 12px;
    align-items: center;

    &::before {
      background: url('../../images/icon-error.svg') no-repeat center center/16px
        16px;
      content: '';
      display: block;
      flex-shrink: 0;
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }

  }
}

label.taxyear-select:first-child {
  margin-bottom: 10px;
}