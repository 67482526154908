@import '../../style/variables';

.lastAction {
  order: 100;
}

.icon-3-dots {
  background: url('../../images/ic-3-dots.svg') no-repeat center;
  display: inline-block;
  width: 17px;
  height: 20px;
}

.actions-column-cell {
  position: relative;
}

.modal {
  width: 337px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: $blank;
  border: 1px solid $modal-border;
  border-radius: 4px;
  box-shadow: 0 5px 20px rgba($color: $black, $alpha: 0.16);
}

.modal-header {
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.modal-content {
  margin-top: 20px;
  margin-bottom: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  &>:not(:first-child) {
    margin-left: 20px;
  }
}

.merge-pdf-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}