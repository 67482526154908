@import '../../style/variables';

.boards {
  background-color: $blank;
  margin-left: 16px;
  width: 100%;
  .inner {
    height: 100%;
  }
  //Re-style swiper
  :global(.swiper) {
    width: 100%;
    height: 100%;
    border-radius: $radius-large;
    overflow: visible;
  }
  :global(.swiper-wrapper) {
    border-radius: $radius-large;
  }
  :global(.swiper-slide) {
    border: $block solid 1px;
    border-radius: $radius-large;
    cursor: pointer;
    padding-top: 24px;
    position: relative;
    margin: 0 4px;
    width: calc(25% - 8px);
    &.upcoming {
      i {
        background-image: url('../../images/icon-event-active.svg');
      }
      .date {
        color: $default;
        small {
          color: $logo;
        }
      }
    }
    &.past-due {
      border: 1px solid $error;
      i {
        background-image: url('../../images/icon-event-past-due.svg');
      }
      .date {
        color: $default;
        small {
          color: $error;
        }
      }
    }
    //Calendar icon
    i {
      background: url('../../images/icon-event.svg') no-repeat center center;
      display: block;
      height: 24px;
      margin: 0 auto;
      width: 24px;
    }
    //Date item
    .date {
      color: $header;
      display: block;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      margin: 0 auto;
      max-width: 85px;
      padding-top: 14px;
      text-align: center;
      small {
        color: $date;
        display: block;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .details {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      position: absolute;
      bottom: 16px;
      width: 100%;
      p {
        color: $label;
        font-size: 15px;
        line-height: 20px;
        padding: 0 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        display: none;
        padding: 0 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    /* Hover on event board,the detail appear */
    &:hover {
      background-color: $geryer-background;
      .details {
        span {
          display: block;
        }
      }
    }
  }
}
