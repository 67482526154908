.btnEdit {
    height: 21px;
}

.textTitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.06px;
}

.divider1 {
    margin-top: 8px;
    margin-bottom: 14px;
}

.blockFieldContainer {
    margin-top: 20px;
}

.divider2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.FormManagementSearch {
    margin-bottom: 16px;
}

.divider3 {
    margin-top: 10px;
    margin-bottom: 40px;
}