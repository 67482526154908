.checkbox {
  label {
    align-items: center;
    font-size: inherit !important;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.selectField {
  :global(s-select) {
    div[data-ref='select-ul-container'] {
      left: 0;
      right: 0;
      height: auto;
      overflow: auto;
      display: flex;
      max-height: 203px;
      background-color: white;
      z-index: 100;
      border: 1px solid var(--color-gray-light);
      border-radius: 4px;
      flex-direction: column;

      div[data-ref='search-input-div'] {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 101;
        min-width: fit-content;
      }

      ul[data-ref='results'] {
        overflow: initial;
        min-width: fit-content;
        max-height: none;
        border: none;
        height: 100%;

        li {
          min-width: 218px;
        }
      }
    }
  }
}

.fieldDate {
  :global(s-datepicker) {
    s-box[data-ref="picker"] {
      max-width: 270px;

      div[data-ref="picker-elements"] {
        overflow: auto;
      }
    }
  }
}